import { AppConfig } from "app_config";
import { apiFetch, handleStageApi, EApiMethods } from "./api_fetch";
import { country_options, EApiResponseType, environmentTypes, IContentInfo } from "components/shared";
import { telekom_brand_options } from "components/shared/constants";
import { setUrlParamsStr } from "utils";

interface IContentFile {
  PK: string;
  SK: string;
  country: string;
  type: string;
  lang: string;
}

export interface IImageFile {
  PK: string;
  SK: string;
  cloudfront_link: string;
  country: string;
  key: string;
  lang: string;
  oem: string;
  type: string;
  resolution: string;
  description: string;
  path: string;
}

export interface IMappedImageFile {
  PK: string;
  sk: string;
  id: string;
  cloudfront_link: string;
  country: string;
  key: string;
  lang: string;
  oem: string;
  type: string;
  description: string;
  resolution: string;
  path: string;
}

export const parse_content_key = (key: string): IContentInfo => {
  // key example review/content/html/bmw/at/en/product.html
  const [type, OEM, country, lang, page] = key.split("/").slice(2);
  return {
    OEM,
    country,
    type: type === "pdf" ? EApiResponseType.pdf : EApiResponseType.html,
    page_name: page.replace(`.${type}`, ""),
    lang,
  };
};
export const get_content_list = async (OEM: string, namespace = "", language = "", signal?: AbortSignal) => {
  const paramsStr = setUrlParamsStr({
    oem: OEM,
    types_include: OEM === "hotspotdrive" ? "pdf" : "html",
    namespace,
    language: namespace && language ? `${namespace}/${language}` : "",
  });
  const url = `/content/list${paramsStr}`;
  const result = await handleStageApi(url, EApiMethods.get, undefined, signal);
  const response = result
    .filter(({ country }: IContentFile) => country_options.find(({ id }) => id === country))
    .map(({ SK, country, type, lang }: IContentFile) => ({
      key: SK,
      type,
      lang,
      country: country_options.find(({ id }) => id === country)?.label,
    }));
  return response;
};

export const get_image_list = async (
  oem: string,
  namespace = "",
  signal?: AbortSignal
): Promise<IMappedImageFile[]> => {
  const is_telekom_brand = telekom_brand_options.find(({ label }) => label === oem);
  const oem_for_fetch = is_telekom_brand ? "telekom" : oem;
  const brand = is_telekom_brand ? oem.toLowerCase() : "";
  const paramsStr = setUrlParamsStr({ oem: oem_for_fetch, types_include: "images", namespace, brand });
  const url = `/content/list${paramsStr}`;
  const result = await handleStageApi(url, EApiMethods.get, undefined, signal);
  const response = result.map(
    ({ SK, key, type, lang, cloudfront_link, oem, country, resolution, description, path }: IImageFile) => ({
      sk: SK,
      id: key,
      country,
      description,
      path,
      resolution,
      cloudfront_link,
      key,
      lang,
      oem,
      type,
    })
  );
  return response;
};

export const get_content_url = async (key: string, fileType: string) => {
  const paramsStr = setUrlParamsStr({ key, fileType });
  const url = `/content/url${paramsStr}`;
  const result = await handleStageApi(url, EApiMethods.get);
  return result;
};

export const post_content_changes = async (
  presigned_url: string,
  file: Blob,
  headers: HeadersInit = {},
  responseType: EApiResponseType = EApiResponseType.json
) => {
  const result = await apiFetch(
    presigned_url,
    {
      method: "PUT",
      headers,
      body: file,
    },
    responseType
  );

  return result;
};

export const get_content_file = async (
  environment: environmentTypes,
  { OEM, country, lang, page_name, type }: IContentInfo
) => {
  const { static_content_base_url } = AppConfig;
  return await apiFetch(
    `${static_content_base_url}/${environment}/content/${type}/${OEM}/${country}/${lang}/${page_name}.${type}`,
    {
      method: "GET",
      headers: {
        "Cache-Control": "no-cache, no-store",
      },
    },
    type
  );
};

export const parse_html_file = async (input_file_name: string, key: string) => {
  const { OEM, country, lang, page_name } = parse_content_key(key);
  const result = await handleStageApi(
    `/content/html`,
    EApiMethods.post,
    JSON.stringify({
      input_file_name,
      page_name,
      OEM,
      country,
      language: lang,
    })
  );
  return result;
};

export const parse_docx_file = async (input_file_name: string, key: string) => {
  const { OEM, country, lang, page_name } = parse_content_key(key);
  const result = await handleStageApi(
    `/content/docx`,
    EApiMethods.post,
    JSON.stringify({
      input_file_name,
      page_name,
      OEM,
      country,
      language: lang,
    })
  );
  return result;
};

export const edit_html_file = async (html_content: string, { OEM, country, lang, page_name }: IContentInfo) => {
  const result = await handleStageApi(
    `/content/edit`,
    EApiMethods.post,
    JSON.stringify({
      html_content,
      page_name,
      OEM,
      country,
      language: lang,
    })
  );
  return result;
};
