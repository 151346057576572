import { GridActionsCellItem } from "@mui/x-data-grid";
import { EDataTableActionIconTypes, ITableDataActionItemProps } from "components/shared";

import { TableIcon } from "./TableIcon";

export const TableActionItem = ({ onClick, actionType, config }: ITableDataActionItemProps<boolean>) => (
  <GridActionsCellItem
    icon={<TableIcon iconType={actionType as EDataTableActionIconTypes} />}
    label={actionType.toUpperCase()}
    sx={{
      color: config?.primary ? "primary.main" : "inherit",
    }}
    className={`textPrimary ${actionType}`}
    color="inherit"
    onClick={(data) => onClick(data)}
  />
);
