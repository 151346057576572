import { FC } from "react";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

interface ConfirmDialogPops {
  isOpen: boolean;
  handleClose: () => void;
  handleConfirm: (value: string) => void;
  title: string;
  text: string;
  value: string;
}

const ConfirmDialog: FC<ConfirmDialogPops> = ({ isOpen, handleClose, handleConfirm, title, text, value }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} sx={{ color: "red" }}>
          Cancel
        </Button>
        <Button onClick={() => handleConfirm(value)} sx={{ color: "green" }} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
