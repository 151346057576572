import { Dispatch, SetStateAction } from "react";
import Box from "@mui/material/Box";
import { GridColDef, GridValidRowModel, GridToolbar } from "@mui/x-data-grid";
import { ITableActions } from "components/shared";
import useTableDataGrid from "./useTableDataGrid";
import { StyledDataGrid } from "./partials";

export interface IDataTable {
  rows: GridValidRowModel[];
  columns: GridColDef[];
  tableActions?: ITableActions<boolean>[];
  showFilter?: boolean;
  showQuickToolbar?: boolean;
  loading?: boolean;
  setSelectedRow?: Dispatch<SetStateAction<string | []>>;
}

export const DataTable = ({
  columns = [],
  rows = [],
  showFilter = true,
  showQuickToolbar = true,
  tableActions = [],
  setSelectedRow,
  loading,
}: IDataTable) => {
  const { dataRows, dataColumns, handleRowEditStop, rowModesModel, processRowUpdate, handleRowModesModelChange } =
    useTableDataGrid({
      columns,
      rows,
      tableActions,
    });

  return (
    <Box sx={{ display: "grid", gridTemplateColumns: "1fr", height: "70vh" }}>
      <StyledDataGrid
        rows={dataRows}
        columns={dataColumns}
        editMode="row"
        getRowHeight={() => "auto"}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={showQuickToolbar ? { toolbar: GridToolbar } : {}}
        slotProps={{
          toolbar: {
            showQuickFilter: showFilter,
          },
        }}
        loading={loading}
        onRowSelectionModelChange={([rowSelectionModel]) => {
          if (!rowSelectionModel || !setSelectedRow) return;
          setSelectedRow(String(rowSelectionModel));
        }}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
      />
    </Box>
  );
};
