import React from "react";
import Grid from "@mui/material/Grid";
import { IFormField } from "components/shared";
import { FormField } from "components/molecules/form-field";
import Button from "@mui/material/Button";

export interface BasicFormProps {
  title: string;
  form: IFormField[];
  handleChange: (id: string, value: string) => void;
  handleValidate: (id: string) => void;
  handleSubmit: () => void;
}

export const BasicForm: React.FC<BasicFormProps> = ({ title, form, handleChange, handleValidate, handleSubmit }) => {
  return (
    <Grid container component="form" spacing={2} sx={{ maxWidth: "70%", margin: "auto" }}>
      <Grid item xs={12}>
        {title}
      </Grid>
      {form.map((f) => {
        const { id, type, label, placeHolder, options, size, value, required, validation } = f;
        return (
          <Grid item key={id} {...size}>
            <FormField
              id={id}
              type={type}
              label={label}
              options={options}
              placeHolder={placeHolder}
              required={required}
              validation={validation}
              value={value}
              handleChange={handleChange}
              handleValidate={handleValidate}
            />
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Button variant="contained" onClick={() => handleSubmit()}>
          {" "}
          Submit{" "}
        </Button>
      </Grid>
    </Grid>
  );
};
