import { Inventory } from "@mui/icons-material";
import ImageIcon from "@mui/icons-material/Image";
import LanIcon from "@mui/icons-material/Lan";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import SourceIcon from "@mui/icons-material/Source";
import TranslateIcon from "@mui/icons-material/Translate";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import type React from "react";
import { useNavigate } from "react-router-dom";

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const handleContentClick = () => {
    navigate("/content");
  };
  const handleImageClick = () => {
    navigate("/image");
  };
  const handleIntlClick = () => {
    navigate("/intl");
  };
  const handleImportClick = () => {
    navigate("/import");
  };
  const handleCIDRClick = () => {
    navigate("/cidr/int");
  };
  const handleFaqClick = () => {
    navigate("/faq");
  };
  const handleSubscriptionsClick = () => {
    navigate("/subscriptions");
  };
  const handlePassesClick = () => {
    navigate("/passes");
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6} lg={4}>
        <Card>
          <CardActionArea onClick={handleContentClick} sx={{ minHeight: 150 }}>
            <CardContent>
              <SourceIcon />
              <Typography variant="h5" component="div">
                Content
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Edit and add HTML and PDF content for application
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Card>
          <CardActionArea onClick={handleImageClick} sx={{ minHeight: 150 }}>
            <CardContent>
              <ImageIcon />
              <Typography variant="h5" component="div">
                Image
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Edit Images for application
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Card>
          <CardActionArea onClick={handleIntlClick} sx={{ minHeight: 150 }}>
            <CardContent>
              <TranslateIcon />
              <Typography variant="h5" component="div">
                Localization
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Edit and add localization keys and translations
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Card>
          <CardActionArea onClick={handleImportClick} sx={{ minHeight: 150 }}>
            <CardContent>
              <TranslateIcon />
              <Typography variant="h5" component="div">
                Localization import
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Import localization keys and translations from .xlsx file
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Card>
          <CardActionArea onClick={handleCIDRClick} sx={{ minHeight: 150 }}>
            <CardContent>
              <LanIcon />
              <Typography variant="h5" component="div">
                CIDR
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Edit and add NAT CIDR Records
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Card>
          <CardActionArea onClick={handleFaqClick} sx={{ minHeight: 150 }}>
            <CardContent>
              <LiveHelpIcon />
              <Typography variant="h5" component="div">
                FAQ
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Edit and add FAQ questions and answers
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Card>
          <CardActionArea onClick={handleSubscriptionsClick} sx={{ minHeight: 150 }}>
            <CardContent>
              <LibraryBooksIcon />
              <Typography variant="h5" component="div">
                Subscription Catalog
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Edit and add subscriptions for OEMs
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Card>
          <CardActionArea onClick={handlePassesClick} sx={{ minHeight: 150 }}>
            <CardContent>
              <Inventory />
              <Typography variant="h5" component="div">
                Passes Catalog
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Edit and add passes for OEMs
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </Grid>
  );
};

export default HomePage;
