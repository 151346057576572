import React from "react";
import { useNavigate, Outlet } from "react-router-dom";

import { validate } from "api/login";
import { GlobalContext } from "root";

// interface SetCountryProps {
//   children: React.ReactNode;
// }
const CheckAuth: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = location;
  const { logout } = React.useContext(GlobalContext);

  const redirect_to_login = () => {
    if (!pathname.includes("/login")) navigate("/login");
  };
  const getApi = React.useCallback(() => {
    const handleApiCall = async () => {
      try {
        const response = await validate();

        const { isAuthorized } = response;
        if (!isAuthorized) {
          logout();
          redirect_to_login();
        } else {
          if (pathname.includes("/login")) navigate("/");
        }
      } catch (ex) {
        console.error(ex);
        redirect_to_login();
      }
    };
    handleApiCall();
  }, [navigate]);
  React.useEffect(() => {
    const userToken = sessionStorage.getItem("userToken") || "";
    console.log(`Check token: ${userToken}`);
    if (!userToken) {
      logout();
      redirect_to_login();
    }
    if (userToken) getApi();
  }, [navigate, getApi]);
  return (
    <>
      <Outlet />
    </>
  );
};

export default React.memo(CheckAuth);
