import Grid from "@mui/material/Grid";
import { FC, useState, useEffect, useCallback } from "react";
import { Dropdown } from "components/atoms/dropdown";
import {
  country_mapping,
  getDropdownOption,
  na_oems,
  namespaces_jumpboard,
  na_namespaces_oem,
  emea_namespaces_oem,
  namespaces_oem,
} from "components/shared/constants";
import { EOEMValues } from "components/shared/enums";
import Box from "@mui/material/Box";
import { SxProps } from "@mui/material/styles";
import FaqEntriesEditor from "components/molecules/faq-keys-editor";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import { FaqRow, ICommonJSON, IDropdownOption, IFaqFunctionBody } from "components/shared";
import { GridRowModel } from "@mui/x-data-grid";
interface FaqEditorProps {
  OEMs: Array<string>;
  categoryOptions: Array<IDropdownOption>;
  category: IDropdownOption | undefined;
  setCategory: (category: IDropdownOption | undefined) => void;
  handleEditSave: (data: IFaqFunctionBody) => void;
  handleAddFaqRow: (oem: string, namespace: string, lang: string, category: string) => void;
  commonData: ICommonJSON;
  faqData: Array<FaqRow>;
  handleChangeLanguage: (oem: string, namespace: string, lang: string) => void;
  handleAddNewCategory: () => void;
  handleCancelNewCategory: () => void;
  handleCategorySave: (data: IFaqFunctionBody) => void;
  handleCategoryDelete: (data: IFaqFunctionBody) => void;
  handleFaqRowDelete: (data: GridRowModel) => Promise<boolean>;
  loading: boolean;
  wrapperCss?: SxProps;
}
interface INamespaceOption extends IDropdownOption {
  country?: string;
  page: string;
}

const FaqEditor: FC<FaqEditorProps> = ({
  wrapperCss,
  OEMs,
  categoryOptions,
  commonData,
  faqData = [],
  loading,
  handleEditSave,
  handleFaqRowDelete,
  handleAddFaqRow,
  handleCategorySave,
  handleCategoryDelete,
  handleChangeLanguage,
  handleAddNewCategory,
  handleCancelNewCategory,
  category,
  setCategory,
}) => {
  const [oemOptions, setOemOptions] = useState<Array<string>>([]);
  const [oem, setOem] = useState<IDropdownOption>();
  const [namespaces, setNamespaces] = useState<Array<INamespaceOption>>(namespaces_jumpboard);
  const [namespace, setNamespace] = useState<INamespaceOption>();
  const [langOptions, setLangOptions] = useState<Array<IDropdownOption>>([]);
  const [lang, setLang] = useState<IDropdownOption>();
  const [showCategoryInput, setShowCategoryInput] = useState<boolean>(false);
  const [categoryInputValue, setCategoryInputValue] = useState<string>("");
  const [newCategoryAdded, setNewCategoryAdded] = useState<boolean>(false);

  const changeOem = (oem?: IDropdownOption) => {
    setOem(oem);
    setOemAvailableLanguages(oem);
  };
  const changeNamespace = (namespace_id?: string, oem_new = "") => {
    const use_namespaces = oem_new === EOEMValues.hotspotdrive ? namespaces_jumpboard : namespaces_oem;
    const namespace_new = namespace_id ? use_namespaces.find(({ id }) => id === namespace_id) : undefined;
    setNamespace(namespace_new);
    const language_locales = country_mapping.find(({ id }) => id === namespace_new?.id)?.locales || [];
    let language_options: IDropdownOption[] = [];
    if (language_locales?.length > 0) {
      language_options = language_locales.map((locale) => getDropdownOption(locale));
    }
    setLangOptions(language_options);
    setLang(undefined);
    setCategory(undefined);
  };

  const changeLang = (lang?: IDropdownOption) => {
    setLang(lang);
    handleChangeLanguage(oem?.id || "", namespace?.id || "", lang?.id || "");
  };

  const changeCategory = (category?: IDropdownOption) => {
    setCategory(category);
  };

  const addNewCategory = () => {
    setShowCategoryInput(true);
    setCategoryInputValue("New category");
    handleAddNewCategory();
    setNewCategoryAdded(true);
  };

  const handleEditCategory = (category: string) => {
    setShowCategoryInput(true);
    setCategoryInputValue(category);
  };

  const handleSaveCategoryChanges = () => {
    handleCategorySave({
      title: faqData.find((i: FaqRow) => i.id === category?.id)?.title || "",
      id: category?.id || "",
      value: categoryInputValue,
      oem: oem?.id || "",
      namespace: namespace?.id || "",
      lang: lang?.id || "",
    });
    setShowCategoryInput(false);
    setNewCategoryAdded(true);
    setCategoryInputValue("");
  };
  const handleCancelCategoryChanges = () => {
    setShowCategoryInput(false);
    setCategoryInputValue("");
    if (newCategoryAdded) handleCancelNewCategory();
  };

  const handleDeleteCategory = () => {
    handleCategoryDelete({
      id: category?.id || "",
      oem: oem?.id || "",
      namespace: namespace?.id || "",
      lang: lang?.id || "",
    });
  };

  const setOemAvailableLanguages = useCallback(
    (oemValue?: IDropdownOption) => {
      setNamespace(undefined);
      setLang(undefined);
      if (oemValue && na_oems.includes(oemValue.id)) {
        setNamespaces(na_namespaces_oem);
      } else if (oemValue) {
        setNamespaces(emea_namespaces_oem);
      }
    },
    [na_oems, setNamespace, setLang, setNamespaces, na_namespaces_oem, emea_namespaces_oem]
  );

  useEffect(() => {
    setOemOptions(OEMs);
  }, [OEMs, setOemOptions]);

  return (
    <Box>
      <Grid container position="relative">
        <Grid container>
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Box>
              <Dropdown
                id="select-oem"
                placeholder="OEM"
                options={oemOptions.map((oem) => getDropdownOption(oem))}
                selectedValue={oem}
                handleChange={(oem) => changeOem(oem)}
                sx={{ minWidth: "180px", mr: 1 }}
              />
              <Dropdown
                id="select-namespace"
                placeholder={oem?.id === EOEMValues.hotspotdrive ? "Namespace" : "Country"}
                options={namespaces}
                selectedValue={namespace}
                handleChange={(ns) => changeNamespace(ns?.id, oem?.id)}
                sx={{ minWidth: "180px", mr: 1 }}
              />
              <Dropdown
                id="select-language"
                placeholder="Language"
                options={langOptions}
                selectedValue={lang}
                handleChange={(lang) => changeLang(lang)}
                sx={{ minWidth: "180px" }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={12} sx={{ mb: 3 }} visibility={categoryOptions.length > 0 ? "visible" : "hidden"}>
          <Dropdown
            id="select-category"
            placeholder="Category"
            options={categoryOptions}
            selectedValue={category}
            handleChange={(category) => changeCategory(category)}
            sx={{ minWidth: "240px", mr: 1 }}
          />
          <Button
            onClick={() => handleEditCategory(category?.label || "")}
            variant="outlined"
            size="large"
            disableElevation
            disabled={!category}
            sx={{ minHeight: "56px", minWidth: "180px", mr: 1 }}
          >
            Edit chosen category
          </Button>
          <Button
            onClick={handleDeleteCategory}
            variant="outlined"
            size="large"
            disableElevation
            disabled={!category}
            sx={{ minHeight: "56px", minWidth: "180px", mr: 1 }}
          >
            Delete chosen category
          </Button>
          <Button
            onClick={addNewCategory}
            variant="contained"
            size="large"
            disableElevation
            sx={{ minHeight: "56px", minWidth: "180px", mr: 1 }}
          >
            Add new category
          </Button>
          <Button
            onClick={() => handleAddFaqRow(oem?.id || "", namespace?.id || "", lang?.id || "", category?.id || "")}
            variant="contained"
            size="large"
            disableElevation
            disabled={!category}
            sx={{ minHeight: "56px", minWidth: "180px" }}
          >
            Add new question/answer row
          </Button>
        </Grid>
        {showCategoryInput && (
          <Grid item xs={12} sx={{ mb: 3 }}>
            <TextField
              sx={{ mr: 1, minWidth: "240px" }}
              id="category-input"
              type="text"
              label="Category"
              defaultValue={categoryInputValue}
              onChange={(event) => setCategoryInputValue(event.target.value)}
            />
            <Button
              onClick={handleSaveCategoryChanges}
              variant="contained"
              size="large"
              disableElevation
              sx={{ minHeight: "56px", minWidth: "180px", mr: 1 }}
            >
              Save category changes
            </Button>
            <Button
              onClick={handleCancelCategoryChanges}
              variant="contained"
              size="large"
              disableElevation
              sx={{ minHeight: "56px", minWidth: "180px" }}
            >
              Cancel
            </Button>
          </Grid>
        )}
        <Grid item xs={12} sx={wrapperCss}>
          <FaqEntriesEditor
            commonData={commonData}
            faqData={faqData}
            handleEditSave={handleEditSave}
            handleFaqRowDelete={handleFaqRowDelete}
            loading={loading}
            oem={oem?.id || ""}
            namespace={namespace?.id || ""}
            lang={lang?.id || ""}
            category={category?.id || ""}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FaqEditor;
