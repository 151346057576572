import config from "app_config.json";
const { REACT_APP_STATIC_CONTENT_CF_URL, REACT_APP_ENV_STAGE, REACT_APP_API_URL } = process.env;

const { localeS3Path, localeBucket, contentBucket, contentS3Path } = config;
const env_stage = REACT_APP_ENV_STAGE || "review";

export interface IAppConfig {
  static_content_base_url: string | undefined;
  env_stage: string;
  admin_api_base_url: string | undefined;
  locale_bucket: string;
  locale_path: string;
  content_bucket: string;
  content_path: string;
}
export const AppConfig: IAppConfig = {
  static_content_base_url: REACT_APP_STATIC_CONTENT_CF_URL,
  env_stage,
  admin_api_base_url: `${REACT_APP_API_URL}/${env_stage}/admin`,
  // : `https://bcqvtxyo8i.execute-api.eu-central-1.amazonaws.com/${env_stage}/admin`,
  locale_bucket: localeBucket,
  locale_path: localeS3Path,
  content_bucket: contentBucket,
  content_path: contentS3Path,
};
