import * as React from "react";
import { useTheme, Theme, CSSObject, SxProps } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

// Reusable styles for menu items
const menuItemMixin = (theme: Theme): CSSObject => ({
  paddingLeft: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    paddingLeft: `calc(${theme.spacing(2)} + 4px)`,
  },
});

interface IMenuItemProps {
  handleClick?: () => void;
  icon?: React.ReactNode;
  selected?: boolean;
  text: string;
  wrapperCss?: SxProps;
}

const MenuItem: React.FC<IMenuItemProps> = ({ handleClick, icon, selected = false, text, wrapperCss }) => {
  const theme = useTheme();
  return (
    <ListItem key={text} disablePadding onClick={handleClick} sx={wrapperCss}>
      <ListItemButton sx={{ ...menuItemMixin(theme) }} selected={selected}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText>{text}</ListItemText>
      </ListItemButton>
    </ListItem>
  );
};

export default MenuItem;
