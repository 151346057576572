import { EProductType, type IProduct, type IProductApi, deleteProduct, getProducts, saveProduct } from "api/products";
import ProductsEditor from "components/organisms/products-editor";
import { getEnvironmentType } from "components/shared";
import { type FC, useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GlobalContext } from "root";
import { generateRandomID } from "utils";

const PassesPage: FC = () => {
  const { environment } = useContext(GlobalContext);
  const [productsData, setProductsData] = useState<Array<IProduct>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { OEM } = useParams();

  const environmentType = getEnvironmentType(OEM, environment);

  const loadContent = useCallback(() => {
    const handleProductsApiCall = async () => {
      if (OEM) {
        try {
          setLoading(true);
          const env = getEnvironmentType(OEM, environment);
          const resp = await getProducts(env, OEM, EProductType.pass);
          setProductsData(resp.map((product) => ({ ...product, id: generateRandomID(), isNew: false })));
        } catch (ex) {
          console.error(ex);
        } finally {
          setLoading(false);
        }
      }
    };
    handleProductsApiCall();
  }, [OEM, environment]);

  const handleEditSave = async (product: IProductApi) => {
    if (OEM) {
      try {
        setLoading(true);
        const { SK } = await saveProduct(environmentType, OEM, product);
        toast.success("Product saved.");
        return SK;
      } catch (err) {
        console.error(err);
        let errorMessage = "Error while saving product changes.";
        if (err instanceof Error) {
          const { message } = JSON.parse(err.message);
          if (message) errorMessage = message;
        }
        toast.error(errorMessage);
      } finally {
        setLoading(false);
      }
    }
    return;
  };

  const handleProductDelete = async (SK: string) => {
    if (OEM && SK) {
      try {
        setLoading(true);
        await deleteProduct(environmentType, OEM, SK);
        setProductsData([...productsData.filter(({ SK: checkSK }) => checkSK !== SK)]);
        toast.success("Product deleted.");
      } catch (err) {
        console.error(err);
        toast.error("Error while deleting product.");
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    loadContent();
  }, [loadContent]);

  return (
    <ProductsEditor
      productsData={productsData}
      handleEditSave={handleEditSave}
      handleProductDelete={handleProductDelete}
      loading={loading}
      OEM={OEM}
      type={EProductType.pass}
    />
  );
};

export default PassesPage;
