import { useEffect, useState } from "react";
import {
  GridRowModesModel,
  GridRowModes,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid";
import { EDataTableActionTypes, ITableActions } from "components/shared";
import { getTableActions } from "./TableUtils";

interface IUseTableDataProps {
  rows: GridValidRowModel[];
  columns: GridColDef[];
  tableActions?: ITableActions<boolean>[];
}

const useTableDataGrid = ({ columns = [], rows = [], tableActions = [] }: IUseTableDataProps) => {
  const [dataRows, setDataRows] = useState<GridValidRowModel[]>([]);
  const [dataColumns, setDataColumns] = useState<GridColDef[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = async (newRow: GridRowModel, oldRow: GridRowModel) => {
    const saveRowFunc = tableActions.find(({ actionType }) => actionType === EDataTableActionTypes.edit)?.onClick;

    if (!saveRowFunc) return oldRow;

    const success = await saveRowFunc(newRow, oldRow);

    if (!success) return oldRow;
    const updatedRow = { ...newRow, isNew: false };

    setDataRows(dataRows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  useEffect(() => {
    setDataRows(rows);
  }, [rows]);

  useEffect(() => {
    const columnData = [...columns];

    if (tableActions?.length)
      columnData.push({
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: (tableActions.length + 1) * 45,
        cellClassName: "actions",
        getActions: ({ id, row }) =>
          getTableActions(
            tableActions.map(({ actionType, onClick, props }) => ({
              actionType,
              onClick: () => onClick({ ...row, ...props }),
              config: { id: String(id), mode: rowModesModel[id]?.mode, row },
              ...(actionType === EDataTableActionTypes.edit && {
                onSave: handleSaveClick(id),
                onCancel: handleCancelClick(id),
                onEdit: handleEditClick(id),
              }),
            }))
          ),
      });
    setDataColumns(columnData);
  }, [columns, rowModesModel, tableActions]);

  return {
    dataRows,
    dataColumns,
    rowModesModel,
    handleRowEditStop,
    handleEditClick,
    handleSaveClick,
    handleCancelClick,
    processRowUpdate,
    handleRowModesModelChange,
  };
};

export default useTableDataGrid;
