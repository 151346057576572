import React from "react";
import SaveIcon from "@mui/icons-material/Save";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";

export interface SaveContentProps {
  id?: string;
  enabled: boolean;
  handleSave: () => Promise<void>;
}

export const SaveContent: React.FC<SaveContentProps> = ({ id = "save-editor-content", handleSave, enabled }) => {
  const [saveDisabledState, setSaveDisabledState] = React.useState<boolean>(false);

  React.useEffect(() => {
    setSaveDisabledState(!enabled);
  }, [enabled]);
  const handleButtonClick = () => {
    handleSave();
  };
  return (
    <Box id={id} onClick={handleButtonClick} sx={{ display: "inline-block" }}>
      <IconButton disabled={saveDisabledState}>
        <SaveIcon style={{ color: saveDisabledState ? "grey" : "black" }} />
      </IconButton>
    </Box>
  );
};
