import { ChangeEvent, FC, MouseEvent, useState } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import FileDownloadOutlined from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
// import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import IconButton from "@mui/material/IconButton";
import { Button, DialogContentText, styled, SxProps } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
//import { getContentUrl } from "components/shared/constants";
import { EUploadDocumentTypes, IImageRowActions } from "components/shared";
import { toast } from "react-toastify";
import LoadingButton from "components/atoms/LoadingButton";
// import FormData from "form-data"

const Input = styled("input")({
  display: "none",
});

interface ContentRowImageActionsProps {
  id?: string;
  actions: IImageRowActions;
  handleUpload: (file: Blob, key: string, fileType: keyof typeof EUploadDocumentTypes) => Promise<void>;
  // handleDelete: (key: string) => Promise<void>;
  //filter?: ContentRow;
  wrapperCss?: SxProps;
}

const ContentRowImageActions: FC<ContentRowImageActionsProps> = ({
  actions,
  handleUpload,
  wrapperCss,
  // handleDelete,
  id = "content-row-actions",
}) => {
  const [fileValue, setFileValue] = useState("");
  const [file, setFile] = useState<File | null>(null);

  const [buttonLoading, setButtonLoading] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState("none");
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const { key, cloudfront_link } = actions;
  const url = cloudfront_link || "";

  const allowedFileType = EUploadDocumentTypes.image;

  const handleAcceptImageChange = async () => {
    if (!file) return;
    setButtonLoading(true);

    await handleUpload(file, key, "image" as keyof typeof EUploadDocumentTypes);
    setUploadInProgress("none");
    setFileValue("");
    setFile(null);
    toast("Image updated!");
    setDialogOpen(false);
    setButtonLoading(false);
  };

  const handleDenyImageChange = () => {
    setUploadInProgress("none");
    setFileValue("");
    setFile(null);
    setDialogOpen(false);
    setButtonLoading(false);
  };

  const handleUploadCall = async (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { files, value },
    } = event;

    if (!files?.length) return;

    try {
      setUploadInProgress("flex");

      // tests weather the file has the right extension
      const pattern = new RegExp(`(${allowedFileType.replaceAll(",", "|")})$`, "i");

      if (pattern.test(value)) {
        setFileValue(value);
        setFile(files[0]);
        setDialogOpen(true);
      } else {
        throw `Invalid file type, use file with extension (${allowedFileType})`;
      }
    } catch (err) {
      event.target.value = "";
      console.log(err);
      if (typeof err == "string") toast(err?.replaceAll("|", ", "));
      setUploadInProgress("none");
      throw err;
    }
  };

  const handleDownload = (event: MouseEvent) => {
    event.stopPropagation();
    const el = document.createElement("a");
    const fileName = url.split("/").pop();
    el.setAttribute("href", url);
    el.setAttribute("target", "_blank");
    el.setAttribute("download", fileName ? fileName : "");
    document.body.appendChild(el);
    el.click();
    el.remove();
  };

  return (
    <Box id={id} sx={{ ...wrapperCss, display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Box sx={{ display: "inline-block" }}>
        <IconButton
          aria-label="download content"
          component="span"
          disabled={uploadInProgress === "flex"}
          onClick={(event: MouseEvent) => handleDownload(event)}
        >
          <FileDownloadOutlined />
        </IconButton>
      </Box>
      <label htmlFor={`icon-button-file-${id}`}>
        <Input
          accept={allowedFileType}
          id={`icon-button-file-${id}`}
          value={fileValue}
          type="file"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleUploadCall(event)}
        />
        <IconButton aria-label="upload content" component="span" disabled={uploadInProgress === "flex"}>
          <FileUploadOutlinedIcon />
        </IconButton>
      </label>
      <Box sx={{ display: uploadInProgress }}>
        <CircularProgress size={24} />
      </Box>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">You are about to change image!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to upload the image <mark style={{ textTransform: "capitalize" }}>{file?.name}</mark>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="outlined" disabled={buttonLoading} onClick={handleDenyImageChange}>
            Cancel
          </Button>
          <LoadingButton
            size="small"
            variant="contained"
            onClick={handleAcceptImageChange}
            autoFocus
            buttonText="Confirm"
            disabled={buttonLoading}
            loading={buttonLoading}
          />
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ContentRowImageActions;
