import * as React from "react";
import { GridRowModel, GridValidRowModel } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { SxProps } from "@mui/material";
import { FaqRow, FaqEntryRow, ICommonJSON, IFaqFunctionBody, EDataTableActionTypes } from "components/shared";
import { DataTable } from "components/atoms/data-table";

interface FaqKeysEditorProps {
  faqData: Array<FaqRow>;
  commonData: ICommonJSON;
  handleEditSave: (data: IFaqFunctionBody) => void;
  handleFaqRowDelete: (data: GridRowModel) => Promise<boolean>;
  wrapperCss?: SxProps;
  loading: boolean;
  oem: string;
  namespace: string;
  lang: string;
  category: string;
}

const FaqEntriesEditor: React.FC<FaqKeysEditorProps> = ({
  commonData,
  faqData,
  handleEditSave,
  handleFaqRowDelete,
  loading,
  wrapperCss,
  oem,
  namespace,
  lang,
  category,
}) => {
  let entries = faqData.find((cat: FaqRow) => cat.id === category)?.entries || [];
  entries = entries.map((entry: FaqEntryRow, index: number) => {
    return {
      id: index,
      ...entry,
    };
  });

  const processRowUpdate = async (newRow: GridValidRowModel, oldRow?: GridValidRowModel) => {
    const { answer: new_answer, question: new_question } = newRow;
    const { answer: old_answer, question: old_question } = oldRow || {};

    const newAnswer = new_answer !== old_answer && commonData[old_answer] !== new_answer;
    const newQuestion = new_question !== old_question && commonData[old_question] !== new_question;

    if (newAnswer) {
      const req_data = {
        oem,
        namespace,
        lang,
        key: old_answer,
        value: new_answer,
      };
      handleEditSave(req_data);
    }

    if (newQuestion) {
      const req_data = {
        oem,
        namespace,
        lang,
        key: old_question,
        value: new_question,
      };
      handleEditSave(req_data);
    }

    return newAnswer || newQuestion;
  };

  return (
    <Box sx={wrapperCss}>
      <Box key="locale-editor">
        <DataTable
          loading={loading}
          rows={entries}
          columns={[
            {
              headerName: "Question",
              field: "question",
              editable: true,
              flex: 0.3,

              valueGetter: (value) => commonData[value],
            },
            {
              headerName: "Answer",
              field: "answer",
              editable: true,
              flex: 0.3,
              valueGetter: (value) => commonData[value],
            },
          ]}
          tableActions={[
            {
              actionType: EDataTableActionTypes.edit,
              onClick: processRowUpdate,
            },
            {
              actionType: EDataTableActionTypes.delete,
              onClick: handleFaqRowDelete,
              props: {
                oem,
                namespace,
                lang,
                category,
              },
            },
          ]}
        />
      </Box>
    </Box>
  );
};

export default FaqEntriesEditor;
