import { IForm } from "./../components/shared/interfaces";
import { EApiMethods, handleStageApi } from "./api_fetch";

export const post_cidr_entry = async (data: IForm[]) => {
  const result = await handleStageApi(`/cidr`, EApiMethods.post, JSON.stringify(data));
  return result;
};

export const delete_cidr_entry = async (environment: string, id: string) => {
  const result = await handleStageApi(`/cidr/remove`, EApiMethods.post, JSON.stringify({ environment, id }));
  return result;
};
export const get_cidr_entry = async (env: string | undefined) => {
  const result = await handleStageApi(`/cidr?env=${env}`, EApiMethods.get);
  return result;
};
