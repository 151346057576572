import FaqEditor from "components/organisms/faq-editor";
import React from "react";
import { GlobalContext } from "root";
import { get_oem_list } from "api/oem";
import { IDropdownOption, FaqRow, ICommonJSON, IFaqFunctionBody } from "components/shared";
import { get_faq, save_faq_edit } from "api/faq";
import omit from "lodash.omit";
import { generateRandomID } from "utils";
import { GridRowModel } from "@mui/x-data-grid";

const FaqPage: React.FC = () => {
  const { userToken, environment } = React.useContext(GlobalContext);
  const [commonData, setCommonData] = React.useState<ICommonJSON>({});
  const [faqData, setFaqData] = React.useState<Array<FaqRow>>([]);
  const [oemOptions, setOemOptions] = React.useState<Array<string>>([]);
  const [categoryOptions, setCategoryOptions] = React.useState<Array<IDropdownOption>>([]);
  const [category, setCategory] = React.useState<IDropdownOption>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [newKeyAdded, setNewKeyAdded] = React.useState<boolean>(false);
  const [newCategoryAdded, setNewCategoryAdded] = React.useState<boolean>(false);

  const loadContent = React.useCallback(() => {
    const handleOemApiCall = async () => {
      try {
        setLoading(true);
        const resp_array = await get_oem_list();
        setOemOptions(resp_array);
      } catch (ex) {
        console.error(ex);
      } finally {
        setLoading(false);
      }
    };
    handleOemApiCall();
  }, []);

  React.useEffect(() => {
    loadContent();
  }, [userToken, loadContent, environment]);

  const handleChangeLanguage = async (oem: string, namespace: string, lang: string) => {
    try {
      setLoading(true);
      const { common_data, faq_data } = await get_faq({
        OEM: oem,
        country: namespace,
        lang,
      });
      setCommonData(common_data);
      setFaqData(faq_data);
      const cat_options = faq_data.map((category: FaqRow) => {
        return {
          id: category.id,
          label: common_data[category.title],
        };
      });
      setCategoryOptions(cat_options);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleAddFaqRow = (oem: string, ns: string, lang: string, category: string) => {
    let new_key: { answer: string; question: string; anchor_id: string };
    setFaqData((prevState: Array<FaqRow>) => {
      const newState = [...prevState];
      const categoryIndex = newState.findIndex((item) => item.id === category);
      const { entries } = newState[categoryIndex];
      let num = -1;
      if (entries.length) {
        const last_entry = entries[entries.length - 1];
        num = Number(last_entry.answer.replace(/(\w*entry)/g, ""));
      }

      const anchor_id = generateRandomID();

      new_key = {
        answer: `at_faq_answer_${category}_entry${num + 1}`,
        question: `at_faq_question_${category}_entry${num + 1}`,
        anchor_id,
      };

      newState[categoryIndex] = {
        ...newState[categoryIndex],
        entries: [...entries, new_key],
      };
      return newState;
    });

    setCommonData((prevState: ICommonJSON) => {
      return {
        ...prevState,
        [new_key.answer]: "New answer row",
        [new_key.question]: "New question row",
      };
    });
    setNewKeyAdded(true);
  };

  const handleEditSave = async (data: IFaqFunctionBody) => {
    const { key = "", value, oem, namespace, lang } = data;
    try {
      setLoading(true);
      await save_faq_edit({
        OEM: oem,
        country: namespace,
        lang,
        common_json: { ...commonData, [key]: value || "" },
        faq_json: faqData,
        new_keys_added: newKeyAdded,
      });
      setCommonData((prevState: ICommonJSON) => {
        return {
          ...prevState,
          [key]: value || "",
        };
      });
      setNewKeyAdded(false);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleFaqRowDelete = async (data: GridRowModel): Promise<boolean> => {
    try {
      setLoading(true);
      const { oem, namespace, lang, category, answer, question, id } = data;

      let newCommonData = { ...commonData };
      newCommonData = omit(newCommonData, [answer, question]);
      const newFaqData = [...faqData];
      const categoryIndex = newFaqData.findIndex((item) => item.id === category);
      newFaqData[categoryIndex].entries.splice(id, 1);
      await save_faq_edit({
        OEM: oem,
        country: namespace,
        lang,
        common_json: newCommonData,
        faq_json: newFaqData,
        new_keys_added: true,
      });
      setCommonData((prevState: ICommonJSON) => {
        let newCommonData = { ...prevState };
        newCommonData = omit(newCommonData, [answer, question]);
        return newCommonData;
      });
      setFaqData((prevState: Array<FaqRow>) => {
        const newFaqData = [...prevState];
        const categoryIndex = newFaqData.findIndex((item) => item.id === category);
        newFaqData[categoryIndex].entries.splice(id, 1);
        return newFaqData;
      });
      setLoading(false);
      return true;
    } catch (err) {
      console.error(err);
      setLoading(false);
      return false;
    }
  };

  const handleCategorySave = async (data: IFaqFunctionBody) => {
    const { id = "", title = "", value, oem, namespace, lang } = data;
    try {
      setLoading(true);
      await save_faq_edit({
        OEM: oem,
        country: namespace,
        lang,
        common_json: { ...commonData, [title]: value || "" },
        faq_json: faqData,
        new_keys_added: newCategoryAdded,
      });
      setCommonData((prevState: ICommonJSON) => {
        return {
          ...prevState,
          [title]: value || "",
        };
      });
      setCategoryOptions((prevState: Array<IDropdownOption>) => {
        const newState = [...prevState];
        const categoryIndex = newState.findIndex((item) => item.id === id);
        newState[categoryIndex] = { id, label: value || "" };
        return newState;
      });
      setNewCategoryAdded(false);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryDelete = async (data: IFaqFunctionBody) => {
    const { id, oem, namespace, lang } = data;
    try {
      setLoading(true);
      const newFaqData = [...faqData];
      const categoryIndex = newFaqData.findIndex((i) => i.id === id);
      let newCommonData = { ...commonData };
      const categoryForDeletion = newFaqData[categoryIndex];
      const keysForDeletion = [];
      keysForDeletion.push(categoryForDeletion.title);
      categoryForDeletion.entries.forEach((entry) => {
        keysForDeletion.push(entry.answer);
        keysForDeletion.push(entry.question);
      });

      newCommonData = omit(newCommonData, keysForDeletion);
      newFaqData.splice(categoryIndex, 1);

      await save_faq_edit({
        OEM: oem,
        country: namespace,
        lang,
        common_json: newCommonData,
        faq_json: newFaqData,
        new_keys_added: true,
      });
      await handleChangeLanguage(oem, namespace, lang);
      setCategory(undefined);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleAddNewCategory = () => {
    let category_id: string;
    setFaqData((prevState: Array<FaqRow>) => {
      const last_item = prevState[prevState.length - 1];
      const num = Number(last_item.id.replace(/\D*/g, ""));
      category_id = `category${num + 1}`;
      return [
        ...prevState,
        {
          id: category_id,
          title: `at_faq_title_${category_id}`,
          entries: [],
        },
      ];
    });
    setCategoryOptions((prevState: Array<IDropdownOption>) => {
      return [
        ...prevState,
        {
          id: category_id,
          label: "New category",
        },
      ];
    });
    setCategory(() => {
      return { id: category_id, label: "New category" };
    });
    setNewCategoryAdded(true);
  };

  const handleCancelNewCategory = () => {
    setFaqData((prevState: Array<FaqRow>) => {
      const newState = [...prevState];
      newState.pop();
      return newState;
    });
    setCategoryOptions((prevState: Array<IDropdownOption>) => {
      const newState = [...prevState];
      newState.pop();
      return newState;
    });
    setCategory(undefined);
    setNewCategoryAdded(false);
  };

  return (
    <FaqEditor
      commonData={commonData}
      faqData={faqData}
      OEMs={oemOptions}
      category={category}
      setCategory={setCategory}
      categoryOptions={categoryOptions}
      handleEditSave={handleEditSave}
      handleFaqRowDelete={handleFaqRowDelete}
      handleAddFaqRow={handleAddFaqRow}
      handleCategorySave={handleCategorySave}
      handleCategoryDelete={handleCategoryDelete}
      handleAddNewCategory={handleAddNewCategory}
      handleCancelNewCategory={handleCancelNewCategory}
      handleChangeLanguage={handleChangeLanguage}
      loading={loading}
    />
  );
};

export default FaqPage;
