import { ICommonJSON } from "components/shared/interfaces";
import { FaqRow } from "components/shared";
import { EApiMethods, handleStageApi } from "./api_fetch";

interface FaqReq {
  OEM: string;
  country: string;
  lang: string;
}

interface FaqPostReq {
  OEM: string;
  country: string;
  lang: string;
  faq_json?: Array<FaqRow>;
  common_json: ICommonJSON;
  new_keys_added?: boolean;
}

export const get_faq = async ({ OEM, country, lang }: FaqReq) => {
  const key = `${OEM}/${country}/${lang}`;
  const result = await handleStageApi(`/faq/get?key=${key}`, EApiMethods.get);
  return result;
};

export const save_faq_edit = async ({
  OEM,
  country,
  lang,
  faq_json,
  common_json,
  new_keys_added = false,
}: FaqPostReq) => {
  const key = `${OEM}/${country}/${lang}`;
  const result = await handleStageApi(
    `/faq/save`,
    EApiMethods.post,
    JSON.stringify({ key, faq_json, common_json, new_keys_added })
  );
  return result;
};
