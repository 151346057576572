import ContentEditorIntl from "components/molecules/content-editor-intl";
import * as React from "react";
import ContentViewer from "components/molecules/content-viewer";
//import ContentEditorFaq from 'components/molecules/content-editor-faq';
import { IIntlReq, ISaveResult } from "components/molecules/locale-editor";
import { IImportResult } from "components/molecules/locale-import";
import { ContentRow, IDropdownOption, IIntlKeys, IContentInfo, EUploadDocumentTypes } from "components/shared";
import ContentEditorImage from "components/molecules/content-editor-image";
import { IMappedImageFile } from "api/content";
//import {ContentTypes} from 'components/constants'

interface ContentEditorProps {
  content_rows: Array<ContentRow>;
  image_rows: IMappedImageFile[];
  OEMs: Array<string>;
  imageOEMs: Array<string>;
  handleUpload: (file: Blob, key: string, fileType: keyof typeof EUploadDocumentTypes) => Promise<void>;
  handleChangeOEM: (signal?: AbortSignal, oem?: IDropdownOption) => void;
  handleIntlSave: (req: IIntlReq) => Promise<ISaveResult>;
  handleIntlDelete: (req: IIntlReq) => Promise<ISaveResult>;
  handleIntlImport: (OEMs: string[], namespaces: string[], xlsx: Blob) => Promise<IImportResult>;
  getIntlKeys: (req: IIntlReq) => Promise<IIntlKeys>;
  handleEditClick: (req: IContentInfo) => void;
  content_base_url: string;
  content: string;
  isLoading: boolean;
  refreshKey: string;
}

const ContentEditor: React.FC<ContentEditorProps> = ({
  content,
  content_base_url,
  content_rows,
  image_rows,
  isLoading,
  OEMs,
  imageOEMs,
  handleUpload,
  handleChangeOEM,
  handleIntlSave,
  handleIntlDelete,
  handleIntlImport,
  getIntlKeys,
  handleEditClick,
  refreshKey,
  // handleDelete,
}) => {
  if (content === "intl" || content === "import") {
    // TODO separate content types as individual organisms
    return (
      <ContentEditorIntl
        OEMs={OEMs}
        handleImport={handleIntlImport}
        content={content}
        handleSave={handleIntlSave}
        handleIntlDelete={handleIntlDelete}
        getIntlKeys={getIntlKeys}
        type={content}
      />
    );
  }
  if (content === "image") {
    return (
      <ContentEditorImage
        OEMs={imageOEMs}
        handleUpload={handleUpload}
        // handleDelete={handleDelete}
        content_rows={image_rows}
        content={content}
        handleChangeOEM={handleChangeOEM}
        isLoading={isLoading}
        refreshKey={refreshKey}
      />
    );
  }
  // if(content === "faq") {
  //   return <ContentEditorFaq userToken={userToken}  />
  // }
  return (
    <ContentViewer
      content={content}
      content_base_url={content_base_url}
      content_rows={content_rows}
      OEMs={OEMs}
      handleUpload={handleUpload}
      handleEditClick={handleEditClick}
      handleChangeOEM={handleChangeOEM}
      isLoading={isLoading}
    />
  );
};

export default ContentEditor;
