import { useParams } from "react-router-dom";
import HtmlEditor from "components/molecules/html-editor";
import React from "react";
// import { GlobalContext } from "root";
// import { get_content_file, edit_html_file } from "api/content";
// import { EApiResponseType } from "api/api_fetch";
//import { AppConfig } from "app_config";
// import { get_content_list, get_content_url, post_content_changes, get_image_list } from "api/content";
// import { save_intl_changes, import_intl_keys, get_intl_keys, get_intl_url } from "api/intl";
// import { IIntlReq } from "components/molecules/locale-editor";
// // import Main from "components/templates/main";
import { country_mapping } from "components/shared/constants";
// import { EApiResponseType } from "api/api_fetch";

//import { ContentTypes } from 'components/constants';
// import styles from '../styles/Home.module.css'
import Box from "@mui/material/Box";

const HtmlEditorPage: React.FC = () => {
  // const handleDelete = async (key: string) => {
  //   // do stuff
  // }
  const { OEM, country, page_name } = useParams();
  if (!OEM || !country || !page_name) return <Box />;

  const country_map = country_mapping.find(({ id }) => id === country);
  const locales = country_map ? country_map.locales : [];
  return (
    <Box>
      {locales.map((locale) => (
        <HtmlEditor key={`html-editor-${locale}`} OEM={OEM} country={country} page_name={page_name} locale={locale} />
      ))}
    </Box>
  );
};

export default HtmlEditorPage;
