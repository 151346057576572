import React from "react";
import Select, { SelectChangeEvent, SelectProps } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

interface IDropdownOption {
  id: string;
  label: string;
}

interface IDropDown {
  id: string;
  options: IDropdownOption[];
  placeholder?: string;
  addEmpty?: boolean;
  handleChange: (selected: IDropdownOption | undefined) => void;
  selectedValue: IDropdownOption | undefined;
}

type IDropDownProps = IDropDown & SelectProps;

export const Dropdown = ({
  id,
  options,
  selectedValue,
  handleChange,
  addEmpty = true,
  placeholder = "-",
  ...rest
}: IDropDownProps) => {
  const new_values = addEmpty ? [{ id: "_", label: placeholder }, ...options] : options;
  const active_value = selectedValue ? selectedValue.id : new_values[0].id;

  const onChange = (event: SelectChangeEvent<unknown>) => {
    const selected = options.find((o) => o.id === event.target.value);
    handleChange(selected);
  };

  return (
    <Select
      id={id}
      sx={{
        backgroundColor: "secondary.main",
        textAlign: "center",
        border: "none",
        borderRadius: "4px",
        paddingLeft: "8px",
        minWidth: "160px",
        padding: 0,
        ".MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      }}
      value={active_value}
      onChange={(e: SelectChangeEvent<unknown>) => onChange(e)}
      {...rest}
    >
      {new_values.map(({ id, label }) => (
        <MenuItem disabled={id === "_"} key={id} id={id} value={id}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};
