import { GridActionsCellItem, GridRowModes } from "@mui/x-data-grid";
import { EDataTableActionTypes, IDataTableActions } from "components/shared";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { generateRandomID } from "utils";
import { TableActionItem } from "./partials";

export const getTableEditRowIconsArray = ({
  actionType,
  config,
  onSave,
  onCancel,
  onEdit,
}: IDataTableActions<boolean>): JSX.Element[] => {
  if (actionType !== EDataTableActionTypes.edit || !onSave || !onEdit || !onCancel || !config.id) return [];

  if (config?.mode === GridRowModes.Edit) {
    return [
      <GridActionsCellItem
        icon={<SaveIcon />}
        label="Save"
        sx={{
          color: "primary.main",
        }}
        onClick={() => onSave(config.id)}
        className="textPrimary save"
        color="inherit"
      />,
      <GridActionsCellItem
        icon={<CancelIcon />}
        label="Cancel"
        onClick={() => onCancel(config.id)}
        className="textPrimary cancel"
        color="inherit"
      />,
    ];
  }

  return [
    <GridActionsCellItem
      icon={<EditIcon />}
      label="Edit"
      onClick={() => onEdit(config.id)}
      className="textPrimary edit"
      color="inherit"
    />,
  ];
};

export const getTableActions = (actions: IDataTableActions<boolean>[]): JSX.Element[] => {
  const tableActions: JSX.Element[] = [];

  actions.forEach(({ actionType, config, onClick, onSave, onCancel, onEdit }) => {
    if (actionType === EDataTableActionTypes.edit) {
      tableActions.unshift(
        ...getTableEditRowIconsArray({
          actionType: actionType,
          config: config,
          onClick: onClick,
          onSave: onSave,
          onCancel: onCancel,
          onEdit: onEdit,
        })
      );
    } else {
      tableActions.push(
        <TableActionItem
          key={`${config.id}_${generateRandomID()}`}
          actionType={actionType}
          onClick={onClick}
          config={config}
        />
      );
    }
  });

  return tableActions;
};
