import Grid from "@mui/material/Grid";
import { useEffect, FC, useState, ChangeEvent, useCallback, useContext } from "react";
import { Dropdown } from "components/atoms/dropdown";
import { all_locales, country_mapping, getDropdownOption } from "components/shared/constants";
import { EOEMValues } from "components/shared/enums";
import Box from "@mui/material/Box";
import { SxProps } from "@mui/material/styles";
//import CircularProgress from '@mui/material/CircularProgress';
import LocaleEditor, { IGenericKeyObject, IIntlReq, ISaveResult } from "components/molecules/locale-editor";
import Button from "@mui/material/Button";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Typography from "@mui/material/Typography";
import { TextField, styled } from "@mui/material";
import LocaleImport, { IImportResult } from "components/molecules/locale-import";
import { IDropdownOption, IIntlKeys } from "components/shared";
import LoadingButton from "components/atoms/LoadingButton";
import { useSearchParams } from "react-router-dom";
import { getOemAvailableCountries } from "utils";
import { GlobalContext } from "root";
import useDebounce from "hooks";
//import {ContentTypes} from 'components/constants'
interface ContentEditorIntlProps {
  //content: ContentTypes
  OEMs: string[];
  content: string;
  handleSave: (req: IIntlReq) => Promise<ISaveResult>;
  handleIntlDelete: (req: IIntlReq) => Promise<ISaveResult>;
  handleImport: (OEMs: string[], namespaces: string[], xlsx: Blob) => Promise<IImportResult>;
  getIntlKeys: (req: IIntlReq) => Promise<IIntlKeys>;
  type: "intl" | "import";
  wrapperCss?: SxProps;
}
interface INamespaceOption extends IDropdownOption {
  country?: string;
  page: string;
}

const Input = styled("input")({
  display: "none",
});
interface userParamsType {
  oem?: IDropdownOption;
  namespace?: INamespaceOption;
  searchKey?: string;
}

const ContentEditorIntl: FC<ContentEditorIntlProps> = ({
  wrapperCss,
  OEMs,
  type,
  getIntlKeys,
  handleSave,
  handleIntlDelete,
  handleImport,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { environment } = useContext(GlobalContext);

  const [params, setParams] = useState<userParamsType>(() => {
    const paramsArray = [...searchParams];
    const tmpObj = Object.fromEntries(paramsArray);
    const paramsObj: userParamsType = { ...tmpObj };

    if (tmpObj.oem) {
      paramsObj.oem = getDropdownOption(tmpObj.oem);
    }
    if (tmpObj.namespace && tmpObj.oem) {
      const availableOptions = getOemAvailableCountries(paramsObj.oem);
      paramsObj.namespace = availableOptions.find(({ id }) => id === tmpObj.namespace);
    }

    return paramsObj;
  });
  const [searchKey, setSearchKey] = useState<string>(params.searchKey || "");
  const debouncedSearchKey = useDebounce<string>(searchKey);

  const [oemOptions, setOemOptions] = useState<string[]>([]);
  const [namespaces, setNamespaces] = useState<INamespaceOption[]>([]);

  const [uploadStarted, setUploadStarted] = useState("none");
  const [xlsxFile, setXLSXFile] = useState<Blob>();
  const [allKeys, setAllKeys] = useState<IGenericKeyObject[] | undefined>();
  const [intlKeys, setIntlKeys] = useState<IIntlKeys[] | undefined>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<string | []>([]);

  const changeOem = useCallback(
    (oem?: IDropdownOption) => {
      setParams((prev) => ({ ...prev, oem, namespace: undefined }));
      const availableNamespaces = getOemAvailableCountries(oem);
      setNamespaces(availableNamespaces);
    },
    [setNamespaces, setParams, getOemAvailableCountries]
  );

  const changeNamespace = useCallback(
    (namespace_id?: string) => {
      const namespace = namespace_id ? namespaces.find(({ id }) => id === namespace_id) : undefined;
      setParams((prev) => ({ ...prev, namespace }));
    },
    [namespaces, setParams]
  );

  const handleAddRow = useCallback(() => {
    if (Array.isArray(allKeys)) {
      const { oem, namespace } = params;
      let new_key = "";

      if (oem && namespace) {
        const { country, page } = namespace;
        new_key = `${oem.id}${country ? `_${country}` : ""}_${page}_new`;
        new_key = `${new_key}_${Object.keys(allKeys).filter((key) => key === new_key).length + 1}`;
      }
      setAllKeys((prevKeys) => {
        if (!prevKeys) return;
        return [{ id: prevKeys.length.toString(), key: new_key }, ...prevKeys];
      });
    }
  }, [setAllKeys, params, allKeys]);

  const handleDeleteRow = useCallback(async () => {
    if (Array.isArray(intlKeys) && Array.isArray(allKeys) && selectedRow.length) {
      const { namespace, oem } = params;
      const selectedKey = allKeys.find((r) => r.id === selectedRow);

      if (oem && namespace && selectedKey) {
        try {
          setLoading(true);
          const { country, page } = namespace;
          const promises = intlKeys.map(({ locale }) => {
            const updated_key = { [selectedKey.key]: selectedKey[locale] };

            return handleIntlDelete({
              OEM: oem?.id,
              page,
              country,
              lang: locale,
              updated_key,
            });
          });

          await Promise.all(promises);

          setAllKeys((prev) => prev?.filter((r) => r.id !== selectedRow));
          setLoading(false);
          setSelectedRow([]);
        } catch (e) {
          console.error(e);
          setLoading(false);
        }
      }
    }
  }, [setAllKeys, allKeys, selectedRow, params, intlKeys]);

  const handleXLSXUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { files, value },
    } = event;
    if (value.endsWith(`.xlsx`) && files && files.length) {
      //const {type} = files[0];
      // const buffer = await files[0].arrayBuffer();
      // const data = new Uint8Array(buffer);
      // let arr:string[] = [];
      // data.forEach((b)=>(arr.push(String.fromCharCode(b))));
      //setXLSXFile(arr.join(""));
      setXLSXFile(files[0]);
      setUploadStarted("inline-block");
    } else throw `Invalid file type, use file with extension .xlsx`;
  };

  const handleGetLanguageKeys = useCallback(async () => {
    try {
      const { oem, namespace } = params;
      if (oem && namespace) {
        setSelectedRow([]);
        setAllKeys(undefined);
        setLoading(true);
        const country = namespace?.country;
        const existingLocales = country_mapping.find(({ id }) => id === country);
        const used_locales = existingLocales?.locales || all_locales;

        const key_requests: Promise<IIntlKeys>[] = used_locales.map((locale) =>
          getIntlKeys({ lang: locale, OEM: oem.id, ...namespace, searchKey })
        );

        const keys = await Promise.all(key_requests);

        const intl_keys = keys.length ? keys.filter(({ locale }) => locale) : [];
        setIntlKeys(intl_keys);

        //collect all keys, set unique
        const all_keys: Array<string> = [];
        intl_keys.forEach(({ keys }) => all_keys.push(...Object.keys(keys)));

        setAllKeys(
          [...new Set(all_keys)].sort().map((key, index) => {
            //{id: key, locale, key, value: keys[key]}
            const row: IGenericKeyObject = { id: index.toString(), key };
            intl_keys.forEach(({ locale, keys }) => (row[locale] = keys[key] ? keys[key] : ""));
            return row;
          })
        );
        setLoading(false);
      }
    } catch (ex) {
      setLoading(false);
      setAllKeys(undefined);
      setIntlKeys(undefined);
      throw ex;
    }
  }, [params, getIntlKeys, setAllKeys, setLoading]);

  useEffect(() => {
    setOemOptions(OEMs);
  }, [OEMs]);

  useEffect(() => {
    if (oemOptions.length) {
      if (params.oem) {
        const availableOptions = getOemAvailableCountries(params.oem);
        setNamespaces(availableOptions);
      }
    }
  }, [oemOptions, params.oem]);

  useEffect(() => {
    const { oem, namespace, searchKey } = params;

    setSearchParams({
      ...(oem && { oem: oem.id }),
      ...(namespace && { namespace: namespace.id }),
      ...(searchKey && { searchKey: searchKey }),
    });
    if (oem && namespace) handleGetLanguageKeys();
  }, [params, environment]);

  useEffect(() => {
    setParams((prev) => ({
      ...prev,
      searchKey: debouncedSearchKey,
    }));
  }, [debouncedSearchKey]);

  return (
    <Box>
      <Grid container display={type === "import" ? "flex" : "none"}>
        <Grid item xs={12} mb={4}>
          <Button
            aria-label="Upload .xlsx file for import"
            variant="contained"
            component="label"
            size="large"
            disableElevation
            sx={{ minHeight: "56px" }}
            endIcon={<FileUploadOutlinedIcon />}
          >
            <Typography component="span">Import from .xlsx</Typography>
            <Input
              hidden
              accept=".xlsx"
              id="icon-button-xlsx"
              type="file"
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleXLSXUpload(event)}
            />
          </Button>
        </Grid>
        {/* <Grid item xs={12}>
            <Box sx={{display: uploadInProgress}}>
                <CircularProgress size={30}  />
            </Box>
          </Grid> */}
        <Grid item xs={12}>
          <Box id="locale-import" sx={{ display: uploadStarted }}>
            <LocaleImport handleImport={handleImport} OEMs={oemOptions} xlsxFile={xlsxFile} />
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ justifyContent: "space-between" }}
        visibility={type === "intl" ? "visible" : "hidden"}
        position="relative"
      >
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <Dropdown
            id="select-oem"
            placeholder="OEM"
            options={oemOptions.map((oem) => getDropdownOption(oem))}
            selectedValue={oemOptions.length ? params?.oem : undefined}
            handleChange={(oem) => changeOem(oem)}
            sx={{ minWidth: "180px" }}
          />
          <Dropdown
            id="select-namespace"
            placeholder={params?.oem?.id === EOEMValues.hotspotdrive ? "Namespace" : "Country"}
            options={namespaces}
            selectedValue={namespaces.length ? params?.namespace : undefined}
            handleChange={(ns) => changeNamespace(ns?.id)}
            sx={{ minWidth: "180px" }}
          />
          <TextField
            id="outlined-basic"
            label="Key"
            variant="outlined"
            value={searchKey}
            onChange={({ target: { value } }) => {
              setSearchKey(value);
            }}
          />
        </Box>

        <Box>
          <Button
            onClick={handleDeleteRow}
            variant="contained"
            size="large"
            disableElevation
            sx={{ minHeight: "56px", minWidth: "180px", m: "8px" }}
            disabled={Array.isArray(selectedRow) || loading}
          >
            Delete key
          </Button>
          <LoadingButton
            onClick={handleAddRow}
            variant="contained"
            size="large"
            disableElevation
            sx={{ minHeight: "56px", minWidth: "180px", m: "8px" }}
            buttonText="Add new key"
            loading={loading}
            disabled={allKeys === undefined}
          />
        </Box>
        <Grid item xs={12} sx={{ ...wrapperCss, marginTop: "20px" }}>
          <LocaleEditor
            setSelectedRow={setSelectedRow}
            allKeys={allKeys}
            loading={loading}
            intlKeys={intlKeys}
            oem={params.oem}
            setAllKeys={setAllKeys}
            setLoading={setLoading}
            namespace={params.namespace}
            getIntlKeys={getIntlKeys}
            handleSave={handleSave}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContentEditorIntl;
