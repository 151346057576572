import { alpha, styled } from "@mui/material/styles";
import { DataGrid, gridClasses } from "@mui/x-data-grid";

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: "rgba(255, 255, 255, 1)",
  },
  [`& .${gridClasses.row}`]: {
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
  },
  [`& .${gridClasses.row}.MuiDataGrid-row--editing .MuiDataGrid-cell`]: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
  [`& .${gridClasses.row} .MuiDataGrid-cell`]: {
    display: "flex",
    alignItems: "center",
    padding: 6,
    maxHeight: 250,
    overflow: "auto",
  },
}));
