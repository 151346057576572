import "@telekom/scale-components/dist/scale-components/scale-components.css";
import { defineCustomElements } from "@telekom/scale-components/loader";
import { get_intl_keys, save_intl_changes } from "api/intl";
import {
  EProductType,
  type IProduct,
  type IProductApi,
  getFilteredProducts,
  getProduct,
  saveProduct,
} from "api/products";
import { type ICommonJSON, type IDropdownOption, country_mapping, getEnvironmentType } from "components/shared";
import { moveArrayItem } from "components/shared/helpers";
import { type BaseSyntheticEvent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const defaultDialogOptions = {
  open: false,
  text: "",
  dialogType: "",
};

interface IProductDialog {
  open: boolean;
  text: string;
  type?: "countryChange" | "productCatalog";
  property?: IDropdownOption;
}

interface IOrderedProduct extends IDropdownOption {
  order: number;
}

const defaultProduct = (productSK: string) => ({
  SK: "",
  country: "",
  type: EProductType.pass,
  contractId: "",
  subscriptionTypeId: 0,
  name: "",
  price: 0,
  currency: "",
  volumeValue: 0,
  conditions: [],
  volumeUnit: "",
  hideVolume: false,
  paymentCycleTextKey: { textKey: `product_${productSK}_period`, text: "" },
  freeTrialTextKey: { textKey: `product_${productSK}_free_trial_info`, text: "" },
});

const defaultConditionText = "New Product Information";

type TProps = {
  environment: string;
  productSK: string;
  OEM: string;
  type: EProductType;
};

const useProductCardEditor = ({ environment, productSK, OEM, type }: TProps) => {
  const navigate = useNavigate();
  const [product, setProduct] = useState<IProduct>(() => defaultProduct(productSK));
  const [intlKeys, setIntlKeys] = useState<ICommonJSON>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [language, setLanguage] = useState<IDropdownOption>({ id: "en", label: "en" });
  const [languageOptions, setLanguageOptions] = useState<IDropdownOption[]>([{ id: "en", label: "en" }]);
  const [relatedProductData, setRelatedProductData] = useState<IProduct[]>([]);
  const [initialRelatedProducts, setInitialRelatedProducts] = useState<IOrderedProduct[]>([]);
  const [reorderedRelatedProducts, setReorderedRelatedProducts] = useState<IOrderedProduct[]>([]);
  const [currentProductIndex, setCurrentProductIndex] = useState<number>(0);
  const [dialog, setDialog] = useState<IProductDialog>(defaultDialogOptions);
  const [orderDialogOpen, setOrderDialogOpen] = useState<boolean>(false);

  const loadData = useCallback(() => {
    (async () => {
      try {
        setLoading(true);
        const environmentType = getEnvironmentType(OEM, environment);
        const productResp = await getProduct(environmentType, OEM, productSK, type);
        const { id: lang } = language;
        const { country, conditions } = productResp;
        const { locales } = country_mapping.find(({ id }) => id === country) ?? { locales: ["en"] };

        const [intlKeys, intlKeysEn] = await Promise.all([
          get_intl_keys({
            OEM,
            country,
            lang,
            page: "common",
            searchKey: "",
          }),
          ...(lang !== "en"
            ? [
                get_intl_keys({
                  OEM,
                  country,
                  lang: "en",
                  page: "common",
                  searchKey: "",
                }),
              ]
            : [null]),
        ]);

        const { keys } = intlKeys;
        const { keys: keysEn = {} } = intlKeysEn || {};

        setIntlKeys(keys);

        const sortedConditions =
          conditions
            ?.sort(({ condition: keyA }, { condition: keyB }) => keyA.localeCompare(keyB))
            .map((sc, i) => ({
              ...sc,
              condition: `${sc.condition.substring(0, sc.condition.length - 1)}${i + 1}`,
              order: i + 1,
              text: keys[sc.condition] || keysEn[sc.condition] || "",
            })) || [];

        setProduct({
          ...productResp,
          conditions: sortedConditions,
          paymentCycleTextKey: {
            textKey: productResp?.paymentCycleTextKey || `product_${productSK}_period`,
            text: keys[productResp?.paymentCycleTextKey] || keysEn[productResp?.paymentCycleTextKey] || "month",
          },
          freeTrialTextKey: {
            textKey: productResp?.freeTrialTextKey || `product_${productSK}_free_trial_info`,
            text:
              keys[productResp?.freeTrialTextKey] ||
              keysEn[productResp?.freeTrialTextKey] ||
              "The offer ends automatically.",
          },
        });
        setLanguageOptions(locales.map((locale) => ({ id: locale, label: locale })));
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [language, environment, OEM, productSK, type]);

  const handleReturnToCatalog = () => {
    if (!isChanged) {
      if (type === EProductType.subscription) {
        navigate(`/subscriptions/${OEM}`);
      } else if (type === EProductType.pass) {
        navigate(`/passes/${OEM}`);
      } else {
        navigate(`/subscriptions/${OEM}`);
      }
      return;
    }
    setDialog((prev) => ({
      ...prev,
      open: true,
      text: "There are some unsaved changes in the product card. If you navigate back to the Product Catalog any unsaved changes will be lost. Are you sure you want to navigate back to Product Catalog without saving the product card?",
      type: "productCatalog",
    }));
  };

  const handleNavigationDialogClose = () => {
    setDialog(defaultDialogOptions);
  };

  const handleNavigationDialogConfirm = () => {
    if (dialog.type === "productCatalog") {
      navigate(`/products/${OEM}`);
    } else if (dialog.type === "countryChange" && dialog?.property) {
      setLanguage(dialog.property);
      setDialog(defaultDialogOptions);
      setIsChanged(false);
    }
  };

  const handleChangeLanguage = (selectedLanguage: IDropdownOption) => {
    if (!isChanged) {
      setLanguage(selectedLanguage);
      return;
    }

    setDialog((prev) => ({
      ...prev,
      open: true,
      text: "There are some unsaved changes in the product card. If you change the language any unsaved changes will be lost. Are you sure you want to change the language without saving the product card?",
      type: "countryChange",
      property: selectedLanguage,
    }));
  };

  const handleAddConditionClick = () => {
    const productConditionCount = product?.conditions?.length ?? 0;

    if (productConditionCount === 5) return;

    setProduct((prev) => ({
      ...prev,
      conditions: [
        ...(prev?.conditions ? prev.conditions : []),
        {
          text: `${defaultConditionText} ${productConditionCount + 1}`,
          condition: `product_${product?.SK}_condition_${productConditionCount + 1}`,
          order: productConditionCount + 1,
          touched: false,
        },
      ],
    }));

    setIsChanged(true);
  };

  const handleRemoveConditionClick = (key: string) => {
    const conditions = product?.conditions
      ?.filter(({ condition }) => condition !== key)
      .map((c, i) => ({ ...c, condition: `product_${product?.SK}_condition_${i + 1}` }));
    setProduct((prev) => ({ ...prev, conditions }));
    setIsChanged(true);
  };

  const handleFreeTrialCheckChange = () => {
    setProduct((prev) => ({ ...prev, isFreeTrial: !prev?.isFreeTrial }));
    setIsChanged(true);
  };

  const handlePeriodTextChange = ({ target: { value: paymentCycleText } }: BaseSyntheticEvent) => {
    setProduct((prev) => ({
      ...prev,
      paymentCycleTextKey: {
        ...prev?.paymentCycleTextKey,
        text: paymentCycleText,
      },
    }));
    setIsChanged(true);
  };

  const handleFreeTrialTextChange = ({ target: { value: freeTrialText } }: BaseSyntheticEvent) => {
    setProduct((prev) => ({
      ...prev,
      freeTrialTextKey: {
        ...prev?.freeTrialTextKey,
        text: freeTrialText,
      },
    }));
    setIsChanged(true);
  };

  const handleConditionTextChange = (conditionText: string, index: number) => {
    const conditions = product?.conditions?.map((c, i) => {
      if (i === index) return { ...c, text: conditionText };
      return c;
    });
    setProduct((prev) => ({ ...prev, conditions }));
    setIsChanged(true);
  };

  const handlePassTypeChange = (passType: string) => {
    setProduct((prev) => ({ ...prev, passType }));
    setIsChanged(true);
  };

  const handleSettingProductOrder = (array: IOrderedProduct[]): IOrderedProduct[] =>
    array.map((item, index) => ({
      order: index + 1,
      id: item.id,
      label: item.label,
    }));

  const handleProductOrderChange = (id: number) => {
    const newIndex = id - 1;
    setCurrentProductIndex(newIndex);
    const productToMove = reorderedRelatedProducts.find(({ id }) => id === product.SK);

    if (productToMove) {
      const newProductOrder = moveArrayItem(reorderedRelatedProducts, newIndex, productToMove.order - 1);
      const reorderedProducts = handleSettingProductOrder(newProductOrder);
      setReorderedRelatedProducts(reorderedProducts);
    }
  };

  const handleOrderDialogOpen = async () => {
    if (!reorderedRelatedProducts.length) {
      setLoading(true);
      const environmentType = getEnvironmentType(OEM, environment);
      const filteredProducts = await getFilteredProducts(environmentType, OEM, product.country, type);
      const orderedRelatedProducts = filteredProducts
        .map(({ SK, name, order }, index) => ({
          order: order ?? index + 1,
          id: SK ?? "",
          label: name,
        }))
        .sort((a, b) => a.order - b.order);
      setRelatedProductData(filteredProducts);
      setInitialRelatedProducts(orderedRelatedProducts);
      setReorderedRelatedProducts(orderedRelatedProducts);
    }
    setLoading(false);
    setOrderDialogOpen(true);
  };

  const handleReorderCancel = () => {
    const originalProductIndex = initialRelatedProducts.findIndex((item: IOrderedProduct) => item.id === product.SK);
    setCurrentProductIndex(originalProductIndex + 1);
    setReorderedRelatedProducts(initialRelatedProducts);
    setOrderDialogOpen(false);
  };

  const handleReorderSave = () => {
    setInitialRelatedProducts(reorderedRelatedProducts);
    setIsChanged(true);
    setOrderDialogOpen(false);
  };

  const prepareUpdatedProduct = (product: IProduct, currentProductIndex: number): IProductApi => ({
    ...product,
    conditions: product?.conditions?.map(({ condition, order }) => ({ order, condition })),
    paymentCycleTextKey: product?.paymentCycleTextKey?.textKey,
    freeTrialTextKey: product?.freeTrialTextKey?.textKey,
    order: currentProductIndex,
  });

  const prepareUpdatedKeys = (product: IProduct, intlKeys: ICommonJSON, productSK: string): ICommonJSON => {
    const updatedKeys: ICommonJSON = {};

    for (const [key, value] of Object.entries(intlKeys)) {
      if (!key.includes(`product_${product?.SK}_condition_`)) {
        updatedKeys[key] = value;
      }
    }

    if (product?.conditions) {
      for (const { condition, text } of product.conditions) {
        updatedKeys[condition] = text;
      }
    }

    updatedKeys[`product_${productSK}_period`] = product?.paymentCycleTextKey?.text;
    updatedKeys[`product_${productSK}_free_trial_info`] = product?.freeTrialTextKey?.text;

    return updatedKeys;
  };

  const prepareSaveRelatedProducts = (
    relatedProductData: IProduct[],
    reorderedRelatedProducts: IOrderedProduct[],
    environmentType: string,
    OEM: string
  ): Promise<string>[] => {
    return relatedProductData
      .map((relatedProduct) => {
        if (relatedProduct.SK === product.SK) return null;

        const reorderedProductFound = reorderedRelatedProducts.find(
          (reorderedProduct) => reorderedProduct.id === relatedProduct.SK
        );

        if (reorderedProductFound?.order) {
          const updatedRelatedProduct: IProductApi = {
            ...relatedProduct,
            order: reorderedProductFound.order,
            paymentCycleTextKey: relatedProduct.paymentCycleTextKey?.textKey,
            freeTrialTextKey: relatedProduct.freeTrialTextKey?.textKey,
          };
          return saveProduct(environmentType, OEM, updatedRelatedProduct);
        }
        return null;
      })
      .filter((promise): promise is Promise<string> => promise !== null);
  };

  const saveAllChanges = async (
    updatedProduct: IProductApi,
    updatedKeys: ICommonJSON,
    saveRelatedProductsPromises: Promise<string>[]
  ) => {
    const { country } = product;
    const { id: lang } = language;

    await Promise.all([
      save_intl_changes({
        OEM,
        country,
        lang,
        page: "common",
        updated_key: updatedKeys,
      }),
      saveProduct(environment, OEM, updatedProduct),
      ...saveRelatedProductsPromises,
    ]);
  };

  const handleSuccessfulSave = () => {
    setIsChanged(false);
    toast.success("Product card saved.");
  };

  const handleSaveError = (err: unknown) => {
    console.error(err);
    let errorMessage = "Error while saving product card.";
    if (err instanceof Error) {
      const { message } = JSON.parse(err.message);
      if (message) errorMessage = message;
    }
    toast.error(errorMessage);
  };

  const handleSaveChanges = async () => {
    if (!product || !isChanged) return;

    try {
      setLoading(true);

      const updatedProduct = prepareUpdatedProduct(product, currentProductIndex);
      const updatedKeys = prepareUpdatedKeys(product, intlKeys, productSK);
      const environmentType = getEnvironmentType(OEM, environment);
      const saveRelatedProductsPromises = prepareSaveRelatedProducts(
        relatedProductData,
        reorderedRelatedProducts,
        environmentType,
        OEM
      );

      await saveAllChanges(updatedProduct, updatedKeys, saveRelatedProductsPromises);

      handleSuccessfulSave();
    } catch (err) {
      handleSaveError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();

    // Loads Scale components, so they work correctly!
    defineCustomElements();
  }, [loadData]);

  useEffect(() => {
    const currentProductIndex = reorderedRelatedProducts.findIndex((item: IOrderedProduct) => item.id === product.SK);
    setCurrentProductIndex(currentProductIndex + 1);
  }, [reorderedRelatedProducts, product.SK]);

  return {
    product,
    intlKeys,
    loading,
    isChanged,
    language,
    languageOptions,
    initialRelatedProducts,
    reorderedRelatedProducts,
    currentProductIndex,
    dialog,
    orderDialogOpen,
    handleReturnToCatalog,
    handleNavigationDialogClose,
    handleNavigationDialogConfirm,
    handleChangeLanguage,
    handleAddConditionClick,
    handleRemoveConditionClick,
    handleFreeTrialCheckChange,
    handlePeriodTextChange,
    handleFreeTrialTextChange,
    handleConditionTextChange,
    handlePassTypeChange,
    handleProductOrderChange,
    handleOrderDialogOpen,
    handleReorderCancel,
    handleReorderSave,
    handleSaveChanges,
    setOrderDialogOpen,
  };
};

export default useProductCardEditor;
