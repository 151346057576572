import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";

const StyledDataGrid = styled(DataGrid)(() => ({
  "& .MuiDataGrid-toolbarContainer": {
    borderBottom: "1px solid #e0e0e0",
    padding: "6px",
  },
  "& .MuiDataGrid-columnHeaders": {
    background: "rgba(0, 0, 0, 0.04)",
  },
}));

export default StyledDataGrid;
