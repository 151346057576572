import { add_cidr_record_form } from "./forms";
import { construct_form } from "components/shared/helpers";
import { atom } from "recoil";
import { IForm } from "./../components/shared/interfaces";
export { default as GlobalContext } from "./context";

export const nat_cidr_from = atom<IForm>({
  key: "register_form",
  default: construct_form(add_cidr_record_form),
});
