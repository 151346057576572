import * as React from "react";
//import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
//import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { get_login_url } from "api/login";

export default function LoginPage() {
  const handleLogin = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    location.href = get_login_url();
  };
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src="/admin.png" alt="mainlogo" />
        <Typography component="h1" variant="h6" sx={{ mt: 1 }}>
          Please Sign-in
        </Typography>
        <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
          <Button type="submit" variant="contained" size="large" sx={{ mt: 2, mb: 2 }}>
            Sign In
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
