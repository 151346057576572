import React from "react";
import { Theme, ThemeProvider as MuiThemeProvider } from "@mui/material";
import tispTheme from "./theme-styles";

export interface ThemeProviderProps {
  theme?: Theme;
  children: React.ReactNode;
}

export function ThemeProvider({ theme = tispTheme, children }: ThemeProviderProps) {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
