import React from "react";
import Box from "@mui/material/Box";
import CodeEditor from "@uiw/react-textarea-code-editor";
// import TextareaAutosize from "@mui/material/TextareaAutosize";

export interface HtmlCodeEditorProps {
  id?: string;
  visible: boolean;
  htmlContent: string;
  handleAfterChange: (editor_html: string) => void;
}

export const HtmlCodeEditor: React.FC<HtmlCodeEditorProps> = ({
  id = "html-code-editor",
  htmlContent,
  handleAfterChange,
  visible,
}) => {
  const [htmlCodeValue, setHtmlCodeValue] = React.useState<string>("");
  React.useEffect(() => {
    setHtmlCodeValue(htmlContent);
  }, [htmlContent]);
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const editor_html = event.target.value;
    setHtmlCodeValue(editor_html);
    handleAfterChange(editor_html);
  };
  return (
    <Box id={id} sx={{ width: "100%", display: visible ? "inline-block" : "none", height: "20em", overflow: "scroll" }}>
      {/* <TextareaAutosize
          style={{ width: "100%", height: "20em", overflow: "scroll" }}
          minRows={10}
          value={htmlCodeValue}
          onChange={handleChange}
        /> */}
      <CodeEditor
        value={htmlCodeValue}
        language="html"
        placeholder="Edit HTML content here"
        onChange={handleChange}
        padding={15}
        style={{
          fontSize: 12,
          backgroundColor: "#f5f5f5",
          fontFamily: "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
        }}
      />
    </Box>
  );
};
