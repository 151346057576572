import { useCallback, useState, FC, Dispatch, SetStateAction } from "react";
import Box from "@mui/material/Box";
import { SxProps } from "@mui/material/styles";
import Snackbar from "@mui/material/Snackbar";
import Alert, { AlertProps } from "@mui/material/Alert";
import { EDataTableActionTypes, IDropdownOption, IIntlKeys, INamespaceOption } from "components/shared";
import { DataTable } from "components/atoms/data-table";

export interface ISaveResult {
  status: number;
  response: string;
}

export interface IGetLocaleProps {
  country?: string;
  OEM: string;
  page: string;
  searchKey?: string;
}

export interface IIntlReq extends IGetLocaleProps {
  lang: string;
  updated_key?: { [key: string]: string };
}

interface LocaleEditorProps {
  allKeys?: IGenericKeyObject[];
  setAllKeys: Dispatch<SetStateAction<IGenericKeyObject[] | undefined>>;
  intlKeys?: IIntlKeys[];
  oem?: IDropdownOption;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  namespace?: INamespaceOption;
  handleSave: (req: IIntlReq) => Promise<ISaveResult>;
  getIntlKeys: (req: IIntlReq) => Promise<IIntlKeys>;
  wrapperCss?: SxProps;
  setSelectedRow: Dispatch<SetStateAction<string | []>>;
}

export interface IGenericKeyObject {
  [key: string]: string;
}

const LocaleEditor: FC<LocaleEditorProps> = ({
  allKeys = [],
  intlKeys = [],
  setAllKeys,
  namespace,
  oem,
  handleSave,
  wrapperCss,
  loading,
  setSelectedRow,
  setLoading,
}) => {
  const [snackbar, setSnackbar] = useState<AlertProps | null>(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  const processRowUpdate = useCallback(
    async (newRow: IGenericKeyObject): Promise<boolean> => {
      try {
        if (oem && namespace) {
          setLoading(true);
          const { key, id } = newRow;

          if (allKeys.filter(({ key: find_key }) => find_key === key).length > 1) {
            setSnackbar({ action: `Duplicate key "${key}" found`, severity: "error" });
            setLoading(false);
            return false;
          }

          const { country, page } = namespace;
          const promises = intlKeys.map(({ locale }) => {
            const updated_key = { [key]: newRow[locale] || "" };
            if (newRow[locale] != null)
              return handleSave({
                OEM: oem?.id,
                page,
                country,
                lang: locale,
                updated_key,
              });
          });

          if (key) {
            const responses = await Promise.all(promises);
            if (responses) {
              setLoading(false);
              setSnackbar({
                action: "Localization changes successfully saved",
                severity: "success",
              });
              setAllKeys((prevKeys) => {
                if (!prevKeys) return;
                const keys = [...prevKeys];
                const index = keys.findIndex((item) => item.id === id);
                keys[index] = newRow;
                return keys;
              });
              return true;
            }
          }
        }
        return false;
      } catch (ex) {
        console.error(ex);
        setSnackbar({
          action: "Error while saving localization changes",
          severity: "error",
        });
        setLoading(false);
        return false;
      }
    },
    [setSnackbar, oem, namespace, allKeys, intlKeys, handleSave, setAllKeys]
  );

  return (
    <Box sx={wrapperCss}>
      <Box key="locale-editor">
        <DataTable
          rows={allKeys}
          columns={[
            { headerName: "Key", field: "key", editable: true, width: 300 },
            ...intlKeys.map(({ locale }) => ({
              headerName: locale,
              field: locale,
              editable: true,
              minWidth: 350,
            })),
          ]}
          tableActions={[
            {
              actionType: EDataTableActionTypes.edit,
              onClick: processRowUpdate,
            },
          ]}
          loading={loading}
          setSelectedRow={setSelectedRow}
        />
      </Box>

      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </Box>
  );
};

export default LocaleEditor;
