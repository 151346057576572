import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { toast } from "react-toastify";
import { GridCellEditStopParams, GridRowSelectionModel, GridToolbar } from "@mui/x-data-grid";
import columns, { ICidrEntry } from "./columns";
import { delete_cidr_entry, get_cidr_entry, post_cidr_entry } from "api/cidr";
import ConfirmButton from "components/molecules/confirm-button";
import { useNavigate, useParams, useLocation } from "react-router";
import { get_venn_difference } from "components/shared/helpers";
import StyledDataGrid from "components/molecules/styled-data-grid";
import Button from "@mui/material/Button";
// import Button from "@mui/material/Button";

const CidrPage: React.FC = () => {
  const { env } = useParams();
  const [rows, setRows] = React.useState<ICidrEntry[]>([]);
  const [originalRows, setOriginalRows] = React.useState<ICidrEntry[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [activeCidrTab, setActiveCidrTab] = React.useState(0);
  const [deleteEnabled, setDeleteEnabled] = React.useState(false);
  const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  // add environment
  React.useEffect(() => {
    const get_data = async () => {
      const resp = await get_cidr_entry(env);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const items = resp.items.map((i: any) => ({ ...i, id: i.SK }));

      setRows(items);
      setOriginalRows(items);
    };
    get_data();
    setLoading(false);
  }, [env]);

  React.useEffect(() => {
    setActiveCidrTab(cidrTabs.findIndex((i) => i.route === pathname.split("/")[2]));
  }, [pathname]);

  const handleSaveChanges = async () => {
    try {
      const updated_elements = get_venn_difference(rows, originalRows);
      await post_cidr_entry(updated_elements);
      toast("Entry posted");
    } catch (err) {
      console.log(err);
      toast.error("Failed to post entry");
    }
  };
  const handleAddRow = () => {
    navigate("/cidr");
  };
  const handleDeleteRow = async () => {
    if (rowSelectionModel && rowSelectionModel.length > 0) {
      setRows(rows.filter(({ id }) => id !== rowSelectionModel[0]));
      const rowToDelete = rows.find(({ id }) => id === rowSelectionModel[0]);
      if (rowToDelete) {
        try {
          const { environment, id } = rowToDelete;
          await delete_cidr_entry(environment, id);
          toast("Entry deleted");
        } catch (err) {
          console.log(err);
          toast.error("Failed to delete entry");
        }
      }
    }
  };
  const handleEditRow = (data: GridCellEditStopParams) => {
    const { id, value, field } = data;
    const new_state = rows.map((r) => {
      const { id: row_id } = r;
      if (row_id === id) {
        return { ...r, [field]: value };
      }
      return r;
    });
    setRows(new_state);
  };

  const navigate_to = (environment: string) => {
    navigate(`/cidr/${environment}`);
  };

  interface ICidrTab {
    label: string;
    route: string;
  }

  const cidrTabs: ICidrTab[] = [
    { label: "Int", route: "int" },
    { label: "TuA", route: "tua" },
    { label: "Prod", route: "prod" },
  ];

  return (
    <Box sx={{ height: "80vh" }}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Tabs value={activeCidrTab}>
            {cidrTabs.map((item) => (
              <Tab key={item.route} component="button" onClick={() => navigate_to(item.route)} label={item.label} />
            ))}
          </Tabs>
        </Grid>
        <Grid item>
          <Button onClick={handleAddRow}>Add new row</Button>
          <ConfirmButton
            confirm_text="Are you sure you want to confirm changes?"
            button_text="Confirm Changes"
            handleConfirm={handleSaveChanges}
          />
          {deleteEnabled && (
            <ConfirmButton
              confirm_text="Are you sure you want to delete the selected row?"
              button_text="Delete ROw"
              handleConfirm={handleDeleteRow}
            />
          )}
        </Grid>
      </Grid>
      <StyledDataGrid
        loading={loading}
        rows={rows}
        columns={columns}
        getRowHeight={() => "auto"}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        slots={{ toolbar: GridToolbar }}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
          setDeleteEnabled(newRowSelectionModel.length === 1);
        }}
        onCellEditStop={handleEditRow}
        sx={{ marginTop: 2 }}
      />
    </Box>
  );
};

export default CidrPage;
