import { EApiMethods, handleStageApi } from "./api_fetch";

export const get_login_url = () => {
  return "https://d-996723e5f2.awsapps.com/start/#/saml/default/GCC%20%28TISP%29/ins-dc8618763f024554";
};

export const validate = async () => {
  const result = await handleStageApi(`/login/validate`, EApiMethods.get);
  return result;
};
