import { EApiMethods, handleStageApi } from "./api_fetch";

export enum EProductType {
  pass = "pass",
  subscription = "subscription",
  free = "free",
}
export interface IProductCondition {
  condition: string;
  text: string;
  order: number;
  touched?: false;
}
export interface IProductConditionApi {
  condition: string;
  order: number;
  touched?: false;
}

export interface IKeyTextMap {
  textKey: string;
  text: string;
}
export interface IProduct {
  SK?: string;
  country: string;
  type: EProductType;
  contractId: string;
  subscriptionTypeId: number;
  name: string;
  price: number;
  currency: string;
  volumeValue: number;
  volumeUnit: string;
  hideVolume: boolean;
  paymentCycleTextKey: IKeyTextMap;
  freeTrialTextKey: IKeyTextMap;
  conditions?: IProductCondition[];
  isFreeTrial?: boolean;
  passType?: string;
  order?: number;
}

export interface IProductApi {
  SK?: string;
  country: string;
  type: EProductType;
  contractId: string;
  subscriptionTypeId: number;
  name: string;
  price: number;
  currency: string;
  volumeValue: number;
  volumeUnit: string;
  hideVolume: boolean;
  paymentCycleTextKey: string;
  freeTrialTextKey: string;
  conditions?: IProductConditionApi[];
  isFreeTrial?: boolean;
  passType?: string;
  order?: number;
}

export const saveProduct = async (env: string, OEM: string, product: IProductApi) => {
  const result = await handleStageApi("/products", EApiMethods.post, JSON.stringify({ product, OEM, env }));
  return result;
};

export const deleteProduct = async (env: string, OEM: string, SK: string) => {
  const result = await handleStageApi("/products/remove", EApiMethods.post, JSON.stringify({ env, SK, OEM }));
  return result;
};

export const getProducts = async (env: string, OEM: string, type: EProductType): Promise<IProduct[]> => {
  const { items } = await handleStageApi(`/products?env=${env}&OEM=${OEM}&type=${type}`, EApiMethods.get);
  return items;
};

export const getProduct = async (env: string, OEM: string, SK: string, type: EProductType): Promise<IProductApi> => {
  const product = await handleStageApi(`/products/get?env=${env}&OEM=${OEM}&id=${SK}&type=${type}`, EApiMethods.get);
  return product;
};

export const getFilteredProducts = async (
  env: string,
  OEM: string,
  country: string,
  type?: string
): Promise<IProduct[]> => {
  let url = `/products/filtered?env=${env}&OEM=${OEM}&country=${country}`;
  if (type) {
    url += `&type=${type}`;
  }

  const { items } = await handleStageApi(url, EApiMethods.get);
  return items;
};
