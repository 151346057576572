import { cidr_country_options, oem_options } from "./../components/shared/constants";
import { dropdown_sorter } from "./../components/shared/helpers";
import { EFormFieldTypes, environment_options, EValidationRules, IFormField, TSizeGroup } from "components/shared";

const one_third: TSizeGroup = {
  xs: 12,
  md: 3,
};

const full_size: TSizeGroup = {
  xs: 12,
};

export const add_cidr_record_form: IFormField[] = [
  {
    id: "oem",
    type: EFormFieldTypes.select,
    label: "OEM",
    options: oem_options,
    size: one_third,
    required: true,
    validation: [
      {
        errorText: "Please specify OEM",
        validationRule: EValidationRules.exists,
      },
    ],
    value: "",
  },
  {
    id: "environment",
    type: EFormFieldTypes.select,
    label: "Environment",
    options: environment_options,
    size: one_third,
    required: true,
    validation: [
      {
        errorText: "Please specify environment",
        validationRule: EValidationRules.exists,
      },
    ],
    value: "",
  },
  {
    id: "country",
    type: EFormFieldTypes.select,
    label: "Country",
    options: dropdown_sorter(cidr_country_options),
    size: one_third,
    required: true,
    validation: [
      {
        errorText: "Please specify country",
        validationRule: EValidationRules.exists,
      },
    ],
    value: "",
  },
  // {
  //   id: "region",
  //   type: EFormFieldTypes.select,
  //   label: "Region",
  //   options: dropdown_sorter(region_options),
  //   size: one_third,
  //   required: true,
  //   validation: [
  //     {
  //       errorText: "Please specify region",
  //       validationRule: EValidationRules.exists,
  //     },
  //   ],
  // },
  {
    id: "ip",
    type: EFormFieldTypes.text,
    label: "IP(s)",
    size: full_size,
    required: true,
    validation: [
      {
        errorText: "Please add at least one IP",
        validationRule: EValidationRules.exists,
      },
    ],
    value: "",
  },
];
