import React from "react";
import ImageIcon from "@mui/icons-material/Image";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";

export interface InsertPictureProps {
  id?: string;
  enabled: boolean;
  handleClick: () => Promise<void>;
}

export const InsertPicture: React.FC<InsertPictureProps> = ({ id = "insert-html-picture", enabled, handleClick }) => {
  const [isDisabled, setIsDisabled] = React.useState<boolean>(false);
  React.useEffect(() => {
    setIsDisabled(!enabled);
  }, [enabled]);
  return (
    <Box id={id} onClick={handleClick} sx={{ display: "inline-block" }}>
      <IconButton disabled={isDisabled}>
        <ImageIcon style={{ color: isDisabled ? "grey" : "black" }} />
      </IconButton>
    </Box>
  );
};
