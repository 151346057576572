import { FC, useState, useCallback, useEffect } from "react";
import {
  getDropdownOption,
  namespaces_jumpboard,
  namespaces_oem,
  oem_options,
  telekom_brand_options,
} from "components/shared/constants";
import { Dropdown } from "components/atoms/dropdown";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { SxProps } from "@mui/material";
import ContentRowImageActions from "components/molecules/content-row-image-actions";
import { IDropdownOption } from "components/shared";
import { EOEMValues, EUploadDocumentTypes } from "components/shared/enums";
import { getOemAvailableCountries } from "utils";
import { IMappedImageFile } from "api/content";
import { DataTable } from "components/atoms/data-table";

interface INamespaceOption extends IDropdownOption {
  country?: string;
  page: string;
}

interface ContentEditorImageProps {
  content_rows: IMappedImageFile[];
  OEMs: Array<string>;
  handleChangeOEM: (
    signal?: AbortSignal,
    oem?: IDropdownOption | undefined,
    content?: string,
    namespace?: string
  ) => void;
  handleUpload: (file: Blob, key: string, fileType: keyof typeof EUploadDocumentTypes) => Promise<void>;
  // handleDelete: (key: string) => Promise<any>;
  //   content_base_url: string;
  content: string;
  wrapperCss?: SxProps;
  isLoading?: boolean;
  refreshKey: string;
}

const ContentEditorImage: FC<ContentEditorImageProps> = ({
  content_rows = [],
  OEMs,
  content,
  handleChangeOEM,
  handleUpload,
  // handleDelete,
  isLoading = false,
  wrapperCss,
  refreshKey,
}) => {
  const [rows, setRows] = useState<IMappedImageFile[]>([]);
  const [oem, setOem] = useState<IDropdownOption>();
  const [oemOptions, setOemOptions] = useState<string[]>([]);
  const [namespaces, setNamespaces] = useState<INamespaceOption[]>([]);
  const [namespace, setNamespace] = useState<INamespaceOption>();

  const changeOem = (oem: IDropdownOption | undefined) => {
    setOem(oem);
    setNamespace(undefined);
    const oem_without_brands = telekom_brand_options.find(({ label }) => label === oem?.label)
      ? oem_options.find(({ id }) => id === "telekom")
      : oem;
    const availableNamespaces = getOemAvailableCountries(oem_without_brands);
    setNamespaces(availableNamespaces);
  };

  const changeNamespace = useCallback(
    (namespace_id?: string, oem_new?: string) => {
      const use_namespaces = oem_new === EOEMValues.hotspotdrive ? namespaces_jumpboard : namespaces_oem;
      const namespace_new = namespace_id ? use_namespaces.find(({ id }) => id === namespace_id) : undefined;
      setNamespace(namespace_new);
    },
    [EOEMValues, setNamespace]
  );

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    (async () => {
      handleChangeOEM(signal, oem, content, namespace?.id);
    })();

    return () => {
      abortController.abort();
    };
  }, [OEMs, oem, content, namespace]);

  useEffect(() => {
    setRows(content_rows);
    setOemOptions(OEMs);
  }, [setRows, setOemOptions, content_rows, OEMs]);

  return (
    <Box sx={wrapperCss}>
      <Grid container spacing={1} sx={{ mb: 3 }}>
        <Grid item>
          <Dropdown
            id="select-oem"
            placeholder="OEM"
            options={oemOptions.map((oem) => getDropdownOption(oem))}
            selectedValue={oem}
            handleChange={(oem) => changeOem(oem)}
            sx={{ minWidth: "180px", mr: 1 }}
          />
          <Dropdown
            id="select-namespace"
            placeholder={oem?.id === EOEMValues.hotspotdrive ? "Namespace" : "Country"}
            options={namespaces}
            selectedValue={namespace}
            handleChange={(ns) => changeNamespace(ns?.id, oem?.id)}
            sx={{ minWidth: "180px" }}
          />
        </Grid>
      </Grid>

      <DataTable
        rows={rows}
        columns={[
          {
            headerName: "Preview",
            field: "preview",
            width: 250,
            align: "center",
            renderCell: ({ row: { cloudfront_link } }) => (
              <Box
                sx={{
                  background: `url(${cloudfront_link}?refreshParam=${refreshKey})`,
                  margin: "8px",
                  width: 250,
                  height: 200,
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                }}
              />
            ),
          },
          { headerName: "Resolution", field: "resolution", width: 160 },
          { headerName: "Path", field: "path", width: 120 },
          {
            headerName: "Description",
            field: "description",
            width: 240,
            renderCell: ({ row: { description = "" } }) => {
              if (description) {
                const [info, desc] = description.split("Description:");
                return (
                  <div>
                    <p>{info}</p>
                    <p>{desc}</p>
                  </div>
                );
              }

              return description;
            },
          },
          { headerName: "Country", align: "center", field: "country", width: 100 },
          {
            headerName: "Key",
            field: "key",
            width: 200,
            cellClassName: "key-image-path",
            renderCell: ({ row: { key } }) => (
              <Box
                sx={{
                  wordBreak: "break-all",
                }}
              >
                {key}
              </Box>
            ),
          },
          { headerName: "OEM", field: "oem" },
          {
            headerName: "Actions",
            field: "actions",
            width: 100,
            sortable: false,
            renderCell: ({ row: { id, type, key, oem, cloudfront_link } }) =>
              oem ? (
                <ContentRowImageActions
                  id={`content-row-actions-${id}`}
                  handleUpload={handleUpload}
                  // handleDelete={handleDelete}
                  actions={{ type, key, oem, cloudfront_link }}
                />
              ) : (
                ""
              ),
          },
        ]}
        loading={isLoading}
      />
    </Box>
  );
};

export default ContentEditorImage;
