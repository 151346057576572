import { FC } from "react";

import Button, { ButtonProps } from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

interface LoadingButtonPops extends ButtonProps {
  loading: boolean;
  buttonText: string;
}

const LoadingButton: FC<LoadingButtonPops> = ({ loading, buttonText, disabled, ...rest }) => (
  <Button variant="contained" {...rest} disabled={loading || disabled}>
    {loading && <CircularProgress sx={{ marginRight: "10px" }} size={20} />}
    {buttonText}
  </Button>
);

export default LoadingButton;
