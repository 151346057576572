import * as React from "react";
//import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import { GlobalContext } from "root";
import { useQuery } from "hooks";

export default function LoginConfirmPage() {
  const navigate = useNavigate();
  const { email, token } = useQuery();
  const { userToken, setUserEmail, setUserToken } = React.useContext(GlobalContext);

  const loginConfirm = () => {
    if (token && userToken !== token) {
      console.log(`userToken. Set: ${token}`);
      setUserToken(token);
      if (email) setUserEmail(email);
    }
    navigate("/");
  };
  React.useEffect(() => {
    loginConfirm();
  }, [email, token]);
  return <Container />;
}
