import React from "react";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { IDropdownOption } from "components/shared";

export interface FormFieldSelectProps {
  id: string;
  label: string;
  value: string | undefined;
  options: IDropdownOption[];
  handleChange: (id: string, value: string) => void;
  handleValidate: (id: string, value: string) => void;
  required?: boolean;
  helperText: string[];
  error: boolean;
}

export const FormFieldSelect: React.FC<FormFieldSelectProps> = ({
  id,
  label,
  value,
  options,
  handleChange,
  required,
  handleValidate,
  error,
  helperText,
}) => {
  return (
    <FormControl fullWidth required={required} error={error} variant="standard">
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        labelId={id}
        value={value}
        onChange={(e) => handleChange(id, e.target.value)}
        onBlur={(e) => handleValidate(id, e.target.value)}
      >
        {options.map(({ id, label }) => (
          <MenuItem key={id} id={id} value={id}>
            {label}
          </MenuItem>
        ))}
      </Select>
      {error && helperText.map((t, i) => <FormHelperText key={`${t}=${i}`}>{t}</FormHelperText>)}
    </FormControl>
  );
};
