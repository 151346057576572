import React from "react";
import Radio from "@mui/material/Radio/Radio";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import { environmentTypes } from "components/shared";

interface RadioControlProps {
  id: string;
  label: string;
  value: environmentTypes;
  checked: boolean;
  handleChange: (value: environmentTypes) => void;
}

const RadioControl: React.FC<RadioControlProps> = ({ id, label, value, handleChange, checked }) => {
  return (
    <FormControlLabel
      id={id}
      value={value}
      checked={checked}
      control={<Radio onChange={() => handleChange(value)} />}
      label={label}
    />
  );
};

export default RadioControl;
