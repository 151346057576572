import { Inventory } from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import LanOutlinedIcon from "@mui/icons-material/LanOutlined";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import SourceOutlinedIcon from "@mui/icons-material/SourceOutlined";
import TranslateOutlinedIcon from "@mui/icons-material/TranslateOutlined";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import { Button, DialogContentText, Grid } from "@mui/material";
import MuiAppBar, { type AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import CssBaseline from "@mui/material/CssBaseline";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import RadioGroup from "@mui/material/RadioGroup/RadioGroup";
import MuiToolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { type CSSObject, type SxProps, type Theme, styled, useTheme } from "@mui/material/styles";
import RadioControl from "components/atoms/radio-control";
import MenuItem from "components/molecules/menu-item";
import type { environmentTypes } from "components/shared";
import { oem_options, routes } from "components/shared/constants";
import { Fragment, type ReactNode, useContext, useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "root";

const drawerWidth = 240;
const bgColor = "#f7f7f8";
const txtColor = "#242426";

// Styles of the navbar when it’s open (wide)
const openedMixin = (theme: Theme): CSSObject => ({
  border: 0,
  paddingTop: theme.spacing(1),
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

// Styles of the navbar when it's closed (narrow)
const closedMixin = (theme: Theme): CSSObject => ({
  border: 0,
  paddingTop: theme.spacing(1),
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: theme.spacing(7),
  [theme.breakpoints.up("sm")]: {
    width: theme.spacing(8),
  },
});

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

// Adds styles to the topbar depending on the navigation drawer’s state (open or closed)
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  background: "transparent",
  boxShadow: "none",
  paddingTop: theme.spacing(1),
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

/*  Applies styles to the navigation drawer depending on its state (open or closed)
 *  so that it collapses to a narrow version with visible icons
 *  Source in MUI docs: https://mui.com/material-ui/react-drawer/#mini-variant-drawer
 */
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  background: "#fff",
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  background: "none",
  color: txtColor,
  justifyContent: "center",
  padding: 0,
  width: theme.spacing(7),
  [theme.breakpoints.up("sm")]: {
    padding: 0,
    width: theme.spacing(8),
  },
}));

export type MainProps = {
  //menu: Array<MenuItem>;
  children?: ReactNode;
  menuWrapperCss?: SxProps;
  bodyWrapperCss?: SxProps;
};

interface ISubmenuItem {
  route: string;
  text: string;
}

interface IMenuItem {
  route: string;
  text: string;
  icon?: ReactNode;
  nested?: ISubmenuItem[];
}

interface ISubmenuStatus {
  route: string;
  isOpened: boolean;
}

enum EnvironmentColors {
  prod = "#fad2cf",
  staging = "#fee2d0",
  review = "#d3d7f9",
}

enum EnvironmentLabels {
  prod = "Production",
  staging = "Staging",
  review = "Review",
}

const cidrSubmenu: IMenuItem[] = [
  { route: "/cidr", text: "Add CIDR" },
  { route: "/cidr/int", text: "INT" },
  { route: "/cidr/tua", text: "TuA" },
  { route: "/cidr/prod", text: "Prod" },
];

const subscriptionsSubmenu: IMenuItem[] = oem_options.map(({ id, label }) => ({
  route: `/subscriptions/${id}`,
  text: label,
}));
const passesSubmenu: IMenuItem[] = oem_options.map(({ id, label }) => ({ route: `/passes/${id}`, text: label }));

const menu: IMenuItem[] = [
  { route: "/", text: "Home", icon: <HomeOutlinedIcon /> },
  { route: "/content", text: "Content", icon: <SourceOutlinedIcon /> },
  { route: "/image", text: "Image", icon: <InsertPhotoOutlinedIcon /> },
  { route: "/intl", text: "Localization", icon: <TranslateOutlinedIcon /> },
  { route: "/import", text: "Import .xlsx", icon: <UploadFileOutlinedIcon /> },
  { route: "#", text: "CIDR", icon: <LanOutlinedIcon />, nested: cidrSubmenu },
  { route: "/faq", text: "FAQ", icon: <LiveHelpIcon /> },
  { route: "/subscriptions", text: "Subscriptions", icon: <LibraryBooksIcon />, nested: subscriptionsSubmenu },
  { route: "/passes", text: "Passes", icon: <Inventory />, nested: passesSubmenu },
];

const defaultSubmenuStatus: ISubmenuStatus[] = [
  { route: "#", isOpened: false },
  { route: "/products", isOpened: false },
  { route: "/subscriptions", isOpened: false },
  { route: "/passes", isOpened: false },
];

const Main = ({ children }: MainProps) => {
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const { logout, environment, setEnvironment } = useContext(GlobalContext);
  const [submenuStatus, setSubmenuStatus] = useState<ISubmenuStatus[]>(defaultSubmenuStatus);
  const [currentEnv, setCurrentEnv] = useState<environmentTypes>(environment);
  const theme = useTheme();
  const { content } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const userEmail = sessionStorage.getItem("userEmail") || "";
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const handleLocalEnvironmentChange = (env: environmentTypes) => {
    setCurrentEnv(env);
  };

  const handleGlobalChangeEnvironment = () => {
    setEnvironment(currentEnv);
    handleDialogClose();
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setSubmenuStatus(defaultSubmenuStatus);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleToggleSubmenu = (clickedRoute: string) => {
    setSubmenuStatus(
      defaultSubmenuStatus.map(({ route, isOpened }) =>
        route === clickedRoute ? { route, isOpened: !isOpened } : { route, isOpened }
      )
    );
    if (!open) setOpen(true);
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  useEffect(() => {
    if (pathname.includes("/login")) setIsAuthenticated(false);
    if (!pathname.includes("/login")) setIsAuthenticated(true);
  }, [pathname]);

  const prevEnvRef = useRef(currentEnv);
  useEffect(() => {
    if (prevEnvRef.current !== environment) {
      setCurrentEnv(environment);
    }
    prevEnvRef.current = environment;
  }, [environment]);

  const current_menu = menu.find(({ route }) => route === `/${content}`);

  const routePath = pathname.split("/")[1];
  const routeDetails = pathname.split("/").length > 2 ? pathname.split("/")[2] : "";
  const productsOEM = oem_options.find(({ id }) => id === routeDetails);
  const current_route = routes.find(({ route }) => route === routePath);
  const current_page = current_menu
    ? current_menu.text
    : current_route
    ? productsOEM && routePath === "products"
      ? `Product catalog for ${productsOEM.label}`
      : current_route.title
    : "Choose content to edit";

  if (isAuthenticated) {
    return (
      <Box sx={{ backgroundColor: bgColor, display: "flex", minHeight: "100vh" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open} theme={theme}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ ...(open && { display: "none" }), ml: 0 }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose} sx={{ ...(!open && { display: "none" }) }}>
              <ChevronLeftIcon />
            </IconButton>
          </DrawerHeader>
          <List sx={{ flexGrow: 1 }}>
            {menu.map(({ text, route, icon, nested }) => {
              return nested ? (
                <Fragment key={text}>
                  <MenuItem handleClick={() => handleToggleSubmenu(route)} icon={icon} text={text} />
                  <Collapse
                    in={submenuStatus.find(({ route: subStatusRoute }) => subStatusRoute === route)?.isOpened}
                    sx={{ paddingLeft: "60px" }}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List>
                      {nested.map(({ text: nText, route: nRoute }) => (
                        <MenuItem
                          key={nText}
                          handleClick={() => navigate(nRoute)}
                          selected={pathname === nRoute}
                          text={nText}
                        />
                      ))}
                    </List>
                  </Collapse>
                </Fragment>
              ) : (
                <MenuItem
                  key={text}
                  handleClick={() => navigate(route)}
                  icon={icon}
                  selected={pathname === route}
                  text={text}
                />
              );
            })}
            <Typography
              variant="caption"
              position="absolute"
              sx={{ left: "30px", bottom: "50px", transform: "rotate(-90deg)", transformOrigin: "left" }}
            >
              {userEmail}
            </Typography>
            <Box sx={{ height: "32px" }} />
            <MenuItem
              handleClick={handleLogout}
              icon={<LogoutOutlinedIcon />}
              text="Log out"
              wrapperCss={{ position: "absolute", bottom: 0 }}
            />
          </List>
        </Drawer>

        <Box sx={{ background: bgColor, flex: "1 1 auto", px: 4, py: 1 }}>
          <DrawerHeader sx={{ justifyContent: "flex-start", mb: 2, px: 0 }}>
            <Typography variant="h4" noWrap component="h1" color={txtColor}>
              {current_page}
            </Typography>
          </DrawerHeader>

          {/* Current working environment label */}
          <Box
            sx={{
              backgroundColor: EnvironmentColors[environment],
              width: "100%",
              borderRadius: "5px",
              marginBottom: "10px",
              padding: "15px",
            }}
          >
            <strong>
              Currently working on environment:{" "}
              <span style={{ textTransform: "capitalize", color: "#e20074" }}>{EnvironmentLabels[environment]}</span>
            </strong>

            {/* Environment selector */}
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                <RadioControl
                  id="prod"
                  value="prod"
                  label={EnvironmentLabels.prod}
                  handleChange={handleLocalEnvironmentChange}
                  checked={currentEnv === "prod"}
                />
                <RadioControl
                  id="staging"
                  value="staging"
                  label={EnvironmentLabels.staging}
                  handleChange={handleLocalEnvironmentChange}
                  checked={currentEnv === "staging"}
                />
                <RadioControl
                  id="review"
                  value="review"
                  label={EnvironmentLabels.review}
                  handleChange={handleLocalEnvironmentChange}
                  checked={currentEnv === "review"}
                />
              </RadioGroup>
              <Button
                onClick={() => {
                  if (environment !== currentEnv) handleDialogOpen();
                }}
                disabled={currentEnv === environment}
                variant="contained"
              >
                Confirm
              </Button>
            </Grid>
          </Box>

          {children}
        </Box>

        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"You are about to change environments"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure that you want to change environments from{" "}
              <b style={{ textTransform: "capitalize" }}>{EnvironmentLabels[environment]}</b> to{" "}
              <b style={{ textTransform: "capitalize" }}>{EnvironmentLabels[currentEnv]}</b>?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button size="small" variant="outlined" onClick={handleDialogClose}>
              Cancel
            </Button>
            <Button size="small" variant="contained" onClick={handleGlobalChangeEnvironment} autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
  return <Box>{children}</Box>;
};

export default Main;
