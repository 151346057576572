import { createTheme } from "@mui/material/styles";

const tispTheme = createTheme({
  palette: {
    primary: {
      main: "#e20074",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});

export default tispTheme;
