import { EDataTableActionIconTypes } from "components/shared";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import FileDownloadOutlined from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ArticleIcon from "@mui/icons-material/Article";
import HtmlIcon from "@mui/icons-material/Html";
import { EditDocumentIcon } from "./EditDocumentIcon";

export const TableIcon = ({ iconType }: { iconType: EDataTableActionIconTypes }) => {
  if (iconType === "download") return <FileDownloadOutlined />;
  if (iconType === "upload") return <FileUploadOutlinedIcon />;
  if (iconType === "pdf") return <PictureAsPdfIcon />;
  if (iconType === "html") return <HtmlIcon />;
  if (iconType === "docx") return <ArticleIcon />;
  if (iconType === "save") return <SaveIcon />;
  if (iconType === "cancel") return <CancelIcon />;
  if (iconType === "delete") return <DeleteForeverOutlinedIcon />;
  if (iconType === "editDocument") return <EditDocumentIcon />;

  return <EditIcon />;
};
