import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/material/styles";
import { oem_pages } from "components/shared/constants";
import { CheckboxList } from "components/molecules/checkbox-list";
import CircularProgress from "@mui/material/CircularProgress";

const steps = ["Select OEMs to import", "Select namespaces to import", "Import"];

export interface IImportResult {
  importCount?: number;
}
interface LocaleImportProps {
  xlsxFile?: Blob;
  OEMs: string[];
  handleImport: (OEMs: string[], namespaces: string[], xlsx: Blob) => Promise<IImportResult>;
  wrapperCss?: SxProps;
}
//export default function HorizontalLinearStepper() {
const LocaleImport: React.FC<LocaleImportProps> = ({ OEMs, xlsxFile, handleImport, wrapperCss }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  //const [skipped, setSkipped] = React.useState(new Set<number>());
  const [selectedOEMs, setSelectedOEMs] = React.useState<Array<string>>([]);
  const [namespaces, setNamespaces] = React.useState<Array<string>>([]);
  const [selectedNamespaces, setSelectedNamespaces] = React.useState<Array<string>>([]);
  const [endResult, setEndResult] = React.useState<string>("All selected locales imported to AWS S3");
  //   const isStepOptional = (step: number) => {
  //     return step === 1;
  //   };

  //   const isStepSkipped = (step: number) => {
  //     return skipped.has(step);
  //   };
  const handleStep = async (newStep: number, run_import = false) => {
    // eslint-disable-next-line
    try {
      if (newStep === 1) {
        const use_namespaces = [
          ...new Set(selectedOEMs.length === 1 && selectedOEMs[0] === "hotspotdrive" ? ["common"] : oem_pages),
        ]; //oem_pages_map.filter(({oem})=>(selectedOEMs.includes(oem)));
        //let use_namespaces:string[] = [];
        //oem_namespaces.forEach(({pages})=>(use_namespaces=[...use_namespaces, ...pages]))
        setNamespaces(use_namespaces);
        setSelectedNamespaces(use_namespaces);
      }
      if (newStep === steps.length - 1 && run_import && xlsxFile) {
        //to import
        const { importCount } = await handleImport(selectedOEMs, selectedNamespaces, xlsxFile);
        if (!importCount && importCount != 0) setEndResult("There was an error while importing xlsx file");
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } catch (ex) {
      throw ex;
    }
  };
  const handleNext = async () => {
    setActiveStep((prevActiveStep) => {
      handleStep(prevActiveStep + 1, true);
      return prevActiveStep + 1;
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      handleStep(prevActiveStep - 1);
      return prevActiveStep - 1;
    });
  };

  //   const handleSkip = () => {
  //     if (!isStepOptional(activeStep)) {
  //       // You probably want to guard against something like this,
  //       // it should never occur unless someone's actively trying to break something.
  //       throw new Error("You can't skip a step that isn't optional.");
  //     }

  //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //     setSkipped((prevSkipped) => {
  //       const newSkipped = new Set(prevSkipped.values());
  //       newSkipped.add(activeStep);
  //       return newSkipped;
  //     });
  //   };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSelectedOEMChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, oem: string) => {
    //checked?selectedOEMs.push(id):selectedOEMs.splice(selectedOEMs.indexOf(id),1);
    setSelectedOEMs((prevSelectedOEMs) => {
      if (checked && !prevSelectedOEMs.includes(oem)) {
        prevSelectedOEMs.push(oem);
      }
      if (!checked && prevSelectedOEMs.includes(oem)) {
        prevSelectedOEMs.splice(prevSelectedOEMs.indexOf(oem), 1);
      }
      return prevSelectedOEMs;
    });
  };
  const handleSelectedNamespaceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    namespace: string
  ) => {
    //checked?selectedNamespaces.push(id):selectedNamespaces.splice(selectedNamespaces.indexOf(id),1);
    //setSelectedNamespaces(selectedNamespaces);

    setSelectedNamespaces((prevSelectedNamespaces) => {
      if (checked && !prevSelectedNamespaces.includes(namespace)) {
        prevSelectedNamespaces.push(namespace);
      }
      if (!checked && prevSelectedNamespaces.includes(namespace)) {
        prevSelectedNamespaces.splice(prevSelectedNamespaces.indexOf(namespace), 1);
      }
      return prevSelectedNamespaces;
    });
  };
  React.useEffect(() => {
    setSelectedOEMs(OEMs);
  }, [OEMs, setSelectedOEMs]);
  return (
    <Box sx={{ width: "100%", ...wrapperCss }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>{endResult}</Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
          {activeStep === 0 ? (
            <CheckboxList
              key="oem-list"
              id="oem-list"
              options={OEMs.map((oem) => ({
                id: oem,
                label: oem,
                selected: true,
              }))}
              handleChange={(event, checked, { id }) => handleSelectedOEMChange(event, checked, id)}
            />
          ) : activeStep === 1 && namespaces.length ? (
            <CheckboxList
              key="namespace-list"
              id="namespace-list"
              options={namespaces.map((ns) => ({
                id: ns,
                label: ns,
                selected: true,
              }))}
              add_all_option={true}
              handleChange={(event, checked, { id }) => handleSelectedNamespaceChange(event, checked, id)}
            />
          ) : (
            <Box>
              <Typography>XLSX file import in progress...</Typography>
              <CircularProgress size={30} />
            </Box>
          )}
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {/* {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )} */}
            {activeStep < steps.length - 1 && (
              <Button
                onClick={handleNext}
                disabled={
                  (activeStep === 0 && selectedOEMs.length === 0) ||
                  (activeStep === 1 && selectedNamespaces.length === 0)
                }
              >
                {"Next"}
              </Button>
            )}
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};

export default LocaleImport;
