import { createTheme } from "@mui/material/styles";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#e20074",
      dark: "#c00063",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#A0A4B8",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Barlow",
  },
});

export default defaultTheme;
