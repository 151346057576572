import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import { SxProps } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";

export interface SimpleCheckboxProps {
  selected: boolean;
  label: string;
  id?: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  wrapperCss?: SxProps;
}

export const SimpleCheckbox: React.FC<SimpleCheckboxProps> = ({
  selected,
  handleChange,
  label,
  wrapperCss,
  id = "checkbox",
}) => {
  const [isChecked, setIsChecked] = React.useState<boolean>(selected);
  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setIsChecked(checked);
    handleChange(event, checked);
  };
  React.useEffect(() => {
    setIsChecked(selected);
  }, [selected, setIsChecked]);
  return (
    <FormControlLabel
      sx={{ ...wrapperCss }}
      control={
        <Checkbox
          id={id}
          checked={isChecked}
          onChange={(event, checked) => handleSelectChange(event, checked)}
          inputProps={{ "aria-label": "checkbox" }}
        />
      }
      label={label}
    />
  );
};
