// shorthand any fix in future
import { EApiResponseType } from "components/shared";
export const apiFetch = async (
  url: string,
  settings: RequestInit,
  contentType: EApiResponseType = EApiResponseType.json
) => {
  const customSignal = settings.signal;
  const abortController = new AbortController();
  const { signal } = abortController;

  try {
    const response = await fetch(url, { ...settings, signal: customSignal || signal });
    const { status, redirected } = response;
    if (status > 399) {
      const { message, errors = [] } = await response.json();
      throw new Error(JSON.stringify({ status, message, errors }));
    }

    if (status === 204) {
      return response;
    }

    if (signal.aborted) {
      abortController.abort();
    }
    //for 301 responses
    if (redirected) {
      return response;
    }
    if (contentType === EApiResponseType.json) {
      const data = await response.json();

      abortController.abort();
      if (data.errorType || data.errorMessage) {
        throw new Error(`Failed to fetch. Reason: ${data.errorMessage}`);
      }

      return data;
    }
    return await response.text();
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const defaultHeaders: Record<string, string> = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export enum EApiMethods {
  post = "POST",
  put = "PUT",
  get = "GET",
  delete = "DELETE",
}

export const ApiSettingsMaker = (method: EApiMethods, body: string | undefined, signal?: AbortSignal): RequestInit => {
  const settings = {
    method,
    headers: {
      ...defaultHeaders,
      Authorization: sessionStorage.getItem("userToken") || "",
    },
    signal,
  };
  if (method === EApiMethods.post || method === EApiMethods.put || EApiMethods.delete) {
    return {
      ...settings,
      body,
    };
  }
  return settings;
};

export const handleStageApi = (
  url: string,
  method: EApiMethods,
  body: string | undefined = undefined,
  signal?: AbortSignal
) => {
  const { REACT_APP_API_URL } = process.env;
  const environment = sessionStorage.getItem("environment") || process.env?.REACT_APP_ENV_STAGE;
  const environment_url = `${REACT_APP_API_URL}/${environment}/admin${url}`;
  return apiFetch(environment_url, ApiSettingsMaker(method, body, signal));
};

export const handleApi = (url: string, method: EApiMethods, body: string | undefined = undefined) => {
  return apiFetch(url, ApiSettingsMaker(method, body));
};
