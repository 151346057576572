import { FC } from "react";
import {
  ScaleButton,
  ScaleCard,
  ScaleDivider,
  ScaleIconActionCheckmark,
  ScaleLink,
  ScaleList,
  ScaleListItem,
} from "@telekom/scale-components-react";
import styled from "@emotion/styled";

import Box from "@mui/material/Box";
import { SxProps } from "@mui/material";
import { IProduct, IProductCondition } from "api/products";
import { get_currency_symbol } from "components/shared/helpers";
import { pass_type_options } from "components/shared";

const ProductContainer = styled(ScaleCard)`
  --spacing-body: var(--telekom-spacing-unit-x6) var(--telekom-spacing-unit-x5);
  --radius: var(--telekom-radius-standard);
  color: var(--telekom-color-ui-extra-strong);
  display: block;
  overflow: hidden;
  max-width: 256px;
  position: relative;

  scale-divider {
    --spacing: var(--telekom-spacing-unit-x4) 0;
  }

  scale-link {
    font-size: var(--telekom-typography-font-size-caption);
    line-height: 1;
  }

  scale-button {
    --width: 100%;
    display: block;
    margin: var(--telekom-spacing-unit-x5) 0 var(--telekom-spacing-unit-x1);
  }
`;

const FreeTrialLabel = styled.div`
  background-color: var(--telekom-color-primary-standard);
  border-top-right-radius: var(--telekom-radius-standard);
  border-bottom-left-radius: var(--telekom-radius-standard);
  color: var(--telekom-color-text-and-icon-inverted-standard);
  font: var(--telekom-text-style-body-bold);
  line-height: 1;
  padding: var(--telekom-spacing-unit-x1) var(--telekom-spacing-unit-x2);
  position: absolute;
  inset: 0 0 auto auto;
`;

const ProductTitle = styled.p<{ isPass: boolean }>`
  display: block;
  font: var(--telekom-text-style-heading-6);
  margin: 0 0 var(--telekom-spacing-unit-x4);
  overflow-wrap: anywhere;
  max-width: ${(props) => (props.isPass ? "140px" : "none")};
  text-wrap: pretty;
`;

const ProductVolume = styled.div`
  font: var(--telekom-text-style-heading-5);
  line-height: 1.25rem;
  margin: 0 0 var(--telekom-spacing-unit-x4);
`;

const VolumeLabel = styled.span`
  font-size: 2.5rem;
`;

const ProductConditions = styled(ScaleList)`
  display: block;
  min-height: 136px;

  scale-list-item {
    --line-height: 1.25;
    font-weight: var(--telekom-typography-font-weight-bold);
    margin: var(--telekom-spacing-unit-x1) 0;

    &::part(base) {
      align-items: start;
    }
  }
`;

const ProductPrice = styled.div`
  color: var(--telekom-color-primary-standard);
  font: var(--telekom-text-style-heading-3);
`;

const PeriodLabel = styled.span`
  font: var(--telekom-text-style-heading-5);
  margin-left: var(--telekom-spacing-unit-x2);
  overflow-wrap: anywhere;
`;

const OfferEndsLabel = styled.p`
  color: var(--telekom-color-primary-standard);
  font-size: var(--telekom-typography-font-size-caption);
  margin-top: calc(-1 * var(--telekom-spacing-unit-x3));
  overflow-wrap: anywhere;
`;

const ConditionListItem = styled(ScaleListItem)`
  overflow-wrap: anywhere;
`;

const ProductTypeLabel = styled.div<{ type: string }>`
  background-color: ${(props) =>
    props.type === "worldPass"
      ? "#53BAF2"
      : props.type === "topUp"
      ? "#1BADA2"
      : "var(--telekom-color-primary-standard)"};
  font: var(--telekom-text-style-caption-bold);
  position: absolute;
  inset: var(--telekom-spacing-unit-x7) var(--telekom-spacing-unit-x7) auto auto;
  line-height: 30px;
  min-width: 140px;
  text-align: center;
  transform: translateX(50%) rotate(45deg);
  transform-origin: top center;
`;

interface IProductCardProps {
  product: IProduct;
  isFreeTrial: boolean;
  conditions?: IProductCondition[];
  period: string;
  freeTrialText?: string;
  wrapperCss?: SxProps;
  passType?: string;
}
const ProductsCard: FC<IProductCardProps> = ({
  wrapperCss,
  product,
  isFreeTrial,
  conditions = [],
  period,
  freeTrialText,
  passType,
}) => {
  const currencySymbol = get_currency_symbol(product.country);
  const isPass = product.type === "pass";
  const passTypeOption = pass_type_options.find(({ id }) => id === passType);
  return (
    <Box sx={wrapperCss}>
      <ProductContainer>
        {isFreeTrial && <FreeTrialLabel>free trial</FreeTrialLabel>}
        {passTypeOption && <ProductTypeLabel type={passTypeOption?.id}>{passTypeOption?.label}</ProductTypeLabel>}
        <ProductTitle isPass={isPass}>{product.name}</ProductTitle>
        <ProductVolume>
          <VolumeLabel>{`${product.volumeValue} ${product.volumeUnit}`}</VolumeLabel>
          {!isPass && <PeriodLabel>/{period}</PeriodLabel>}
        </ProductVolume>
        {isFreeTrial && <OfferEndsLabel>{freeTrialText}</OfferEndsLabel>}
        <ProductConditions>
          {conditions.map(({ condition, text }) => (
            <ConditionListItem key={condition} marker={false}>
              <ScaleIconActionCheckmark fill="var(--telekom-color-primary-standard)" size={18} decorative />
              {text}
            </ConditionListItem>
          ))}
        </ProductConditions>
        <ScaleDivider />
        <ProductPrice>
          <span>{`${product.price} ${currencySymbol}`}</span>
          {!isPass && <PeriodLabel>/{period}</PeriodLabel>}
        </ProductPrice>
        <ScaleLink href="#" omitUnderline>
          Tariff details
        </ScaleLink>
        <ScaleButton>Choose</ScaleButton>
        <ScaleLink href="#" omitUnderline>
          Valid in these countries
        </ScaleLink>
      </ProductContainer>
    </Box>
  );
};

export default ProductsCard;
