import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import FormHelperText from "@mui/material/FormHelperText";

export interface FormFieldInputProps {
  id: string;
  label: string;
  value: string | undefined;
  handleChange: (id: string, value: string) => void;
  handleValidate: (id: string, value: string) => void;
  required?: boolean;
  helperText: string[];
  error: boolean;
  type?: string;
}

export const FormFieldInput: React.FC<FormFieldInputProps> = ({
  id,
  label,
  value,
  handleChange,
  required,
  handleValidate,
  error,
  helperText,
  type,
}) => {
  return (
    <FormControl fullWidth required={required} error={error}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Input
        type={type}
        id={id}
        value={value}
        onChange={(e) => handleChange(id, e.target.value)}
        onBlur={(e) => handleValidate(id, e.target.value)}
      />
      {error && helperText.map((t, i) => <FormHelperText key={`${t}=${i}`}>{t}</FormHelperText>)}
    </FormControl>
  );
};
