import { useParams, useNavigate } from "react-router-dom";
import ContentEditor from "components/organisms/content-editor";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { GlobalContext } from "root";
import { get_oem_list } from "api/oem";
import {
  get_content_list,
  get_content_url,
  post_content_changes,
  get_image_list,
  parse_html_file,
  parse_docx_file,
  IMappedImageFile,
} from "api/content";
import { save_intl_changes, import_intl_keys, get_intl_keys, get_intl_url, delete_intl_key } from "api/intl";
import { IIntlReq } from "components/molecules/locale-editor";
// import Main from "components/templates/main";
import {
  ContentRow,
  IDropdownOption,
  EApiResponseType,
  IContentInfo,
  file_upload_mime_types,
  EUploadDocumentTypes,
} from "components/shared";
import { generateRandomID } from "utils";
import { telekom_brand_options } from "components/shared/constants";
import { toast } from "react-toastify";

const ContentPage: FC = () => {
  const { REACT_APP_STATIC_CONTENT_CF_URL } = process.env;
  const { userToken, environment } = useContext(GlobalContext);
  const { content } = useParams();
  const navigate = useNavigate();

  const [refreshKey, setRefreshKey] = useState(generateRandomID());
  const [rows, setRows] = useState<Array<ContentRow>>([]);
  const [imageRows, setImageRows] = useState<IMappedImageFile[]>([]);
  const [oemOptions, setOemOptions] = useState<string[]>([]);
  const [imageOemOptions, setImageOemOptions] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const loadContent = useCallback(() => {
    const handleOemApiCall = async () => {
      try {
        setLoading(true);
        const resp_array = await get_oem_list();
        const image_OEMs = [...resp_array];
        telekom_brand_options.forEach(({ label }) => image_OEMs.push(label));
        //resp_array.unshift("jumpboard")
        setOemOptions(resp_array);
        setImageOemOptions(image_OEMs);
      } catch (ex) {
        console.error(ex);
      } finally {
        setLoading(false);
      }
    };

    handleOemApiCall();
  }, []);

  const handleChangeOEM = async (
    signal?: AbortSignal,
    oem?: IDropdownOption,
    content?: string,
    namespace?: string,
    language?: string
  ) => {
    try {
      setLoading(true);
      if (oem) {
        let get_rows;
        if (content === "image") {
          setRefreshKey(generateRandomID());
          get_rows = await get_image_list(oem.id, namespace, signal);

          setImageRows(get_rows);
        } else {
          get_rows = await get_content_list(oem.id, namespace, language, signal);
          setRows(get_rows);
        }
      } else {
        setRows([]);
        setImageRows([]);
      }
      setLoading(false);
    } catch (ex) {
      console.error(ex);
      setRows([]);
    }
  };

  const handleUpload = async (file: Blob, key: string, fileType: keyof typeof EUploadDocumentTypes) => {
    try {
      if (!fileType) return;
      setLoading(true);
      const file_name = key.replaceAll("/", "_");

      if (fileType === "docx" || fileType === "html") {
        const url_response = await get_content_url(
          `upload_${fileType}/${environment}/${file_name}`,
          file_upload_mime_types[fileType]
        );
        const { url } = url_response;
        await post_content_changes(url, file, {}, EApiResponseType.docx);
        if (fileType === "docx") await parse_docx_file(file_name, key);
        if (fileType === "html") await parse_html_file(file_name, key);
      }

      if (fileType === "pdf") {
        const url_response = await get_content_url(key, file_upload_mime_types[fileType]);
        const { url } = url_response;
        await post_content_changes(
          url,
          file,
          {
            "Content-disposition": "inline",
          },
          EApiResponseType.pdf
        );
      }
      if (fileType === "image") {
        const url_response = await get_content_url(key, file.type);
        const { url } = url_response;

        await post_content_changes(
          url,
          file,
          {
            "Content-Type": file.type,
          },
          EApiResponseType.image
        );

        setImageRows((prev) =>
          prev.map((i) =>
            i.key === key
              ? {
                  ...i,
                  cloudfront_link: `${i.cloudfront_link}?refreshParam=${generateRandomID()}`,
                }
              : i
          )
        );
      }
      toast("Upload Successful!");
    } catch (ex) {
      toast("Upload Went Wrong...");
      console.error(ex);
    }
    setLoading(false);
  };

  const handleIntlSave = async (req: IIntlReq) => {
    const { OEM, country, lang, page, updated_key } = req;
    return await save_intl_changes({ OEM, country: country ? country : "", lang, page, updated_key });
  };

  const handleIntlDelete = async (req: IIntlReq) => {
    const { OEM, country, lang, page, updated_key } = req;
    return await delete_intl_key({ OEM, country: country ? country : "", lang, page, updated_key });
  };

  const handleIntlImport = async (OEMs: string[], namespaces: string[], xlsx: Blob) => {
    const path = `upload_excel/${environment}/import.xlsx`;
    const url_response = await get_intl_url(path);
    const { url } = url_response;
    await post_content_changes(
      url,
      xlsx,
      {
        "Content-Type": "application/vnd.ms-excel",
      },
      EApiResponseType.xlsx
    );
    return await import_intl_keys({ OEMs, namespaces });
    // if(importCount) return {importCount};
    // return {};
  };

  const getIntlKeys = (req: IIntlReq) => {
    const { OEM, country = "", lang, page, searchKey = "" } = req;
    return get_intl_keys({ OEM, country, lang, page, searchKey });
  };

  const handleEditClick = (req: IContentInfo) => {
    const { OEM, country, page_name } = req;
    navigate(`/html-editor/${OEM}/${country}/${page_name}`);
  };

  // const handleDelete = async (key: string) => {
  //   // do stuff
  // }

  useEffect(() => {
    loadContent();
  }, [userToken, loadContent, environment]);

  return (
    <ContentEditor
      content_rows={rows}
      image_rows={imageRows}
      handleChangeOEM={handleChangeOEM}
      handleUpload={handleUpload}
      // handleDelete={handleDelete}
      OEMs={oemOptions}
      imageOEMs={imageOemOptions}
      content_base_url={REACT_APP_STATIC_CONTENT_CF_URL ? REACT_APP_STATIC_CONTENT_CF_URL : ""}
      content={content ? content.toString() : ""}
      handleIntlSave={handleIntlSave}
      handleIntlDelete={handleIntlDelete}
      handleIntlImport={handleIntlImport}
      handleEditClick={handleEditClick}
      getIntlKeys={getIntlKeys}
      isLoading={loading}
      refreshKey={refreshKey}
    />
  );
};

export default ContentPage;
