import React from "react";

import { useRecoilState, useResetRecoilState } from "recoil";

//import { useParams } from "react-router-dom";
import { EFormFieldTypes } from "components/shared/enums";
import { TSizeGroup } from "components/shared/types";
import { IDropdownOption, IndexSignature, IValidationRule } from "components/shared";
import { nat_cidr_from } from "root";
import { validate_form_field } from "components/shared/helpers";
import { BasicForm } from "components/organisms/form";
//import Box from "@mui/material/Box";
//import Button from "@mui/material/Button";
import { post_cidr_entry } from "api/cidr";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

export interface IFormField {
  id: string;
  type: EFormFieldTypes;
  label: string;
  size: TSizeGroup;
  options?: IDropdownOption[];
  placeHolder?: string;
  required?: boolean;
  validation: IValidationRule[];
  value?: string;
}

const CidrForm: React.FC = () => {
  const [form, setForm] = useRecoilState(nat_cidr_from);
  const resetState = useResetRecoilState(nat_cidr_from);
  const navigate = useNavigate();
  const set_form_value = (id: string, value: string) => {
    setForm((oldForm) => ({ ...oldForm, [id]: { ...oldForm[id], value } }));
  };

  const validation = (id: string) => {
    setForm((oldForm) => ({
      ...oldForm,
      [id]: { ...validate_form_field(id, oldForm) },
    }));
  };

  const submit = async () => {
    try {
      const values: IndexSignature = {};
      Object.keys(form).forEach((k: string) => {
        values[k] = form[k].value;
      });
      await post_cidr_entry([values]);
      resetState();
      toast("Entry posted");
    } catch (err) {
      toast.error("Failed to post entry");
    }
  };
  const handleViewList = () => {
    navigate("/cidr/int");
  };
  return (
    <Box sx={{ height: "80vh" }}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Button onClick={handleViewList}>View CIDR List</Button>
        </Grid>
      </Grid>
      <BasicForm
        title={"Add NAT CIDR Record"}
        form={Object.values(form)}
        handleChange={set_form_value}
        handleValidate={validation}
        handleSubmit={submit}
      />
    </Box>
  );
};

export default CidrForm;
