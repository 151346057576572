import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import ContentEditable, { ContentEditableEvent } from "react-contenteditable";

import { get_content_file, edit_html_file, get_image_list } from "api/content";
import { AppConfig } from "app_config";

import sanitizeHtml from "sanitize-html";
import { EApiResponseType, IDropdownOption, ImageRow } from "components/shared";
import { get_sanitizer_conf } from "components/shared/helpers";
import { HtmlCodeEditor } from "components/molecules/html-code-editor";
import { SaveContent } from "components/atoms/save-content";
import { ShowHtmlCode } from "components/atoms/show-html-code";
import { InsertPicture } from "components/atoms/insert-picture";
import { Dropdown } from "components/atoms/dropdown";
import { GlobalContext } from "root";
import { FC, FocusEvent, useCallback, useContext, useEffect, useRef, useState } from "react";

interface HtmlEditorProps {
  OEM: string;
  country: string;
  page_name: string;
  locale: string;
}
const HtmlEditor: FC<HtmlEditorProps> = ({ OEM, country, page_name, locale }) => {
  const { static_content_base_url } = AppConfig;
  const { environment } = useContext(GlobalContext);
  const sanitize_conf = get_sanitizer_conf(
    `${static_content_base_url}/${environment}/content/images/${OEM}/${country}`
  );
  const full_height = "40em";
  const css_link = "/info.css";
  // doing this instead of useState because of a bug in editor component
  //const htmlContent = useRef('');
  const editor_html_ref = useRef("");
  const [htmlContent, setHtmlContent] = useState<string>("");
  const [editorHeight, setEditorHeight] = useState<string>(full_height);
  const [saveEnabledState, setSaveEnabledState] = useState<boolean>(false);
  const [showEditorCode, setShowEditorCode] = useState<boolean>(false);

  const [selectedText, setSelectedText] = useState<string>();
  //const [editorTarget, setEditorTarget] = useState<Node | null>();

  //image insert
  const [showImageChoice, setShowImageChoice] = useState<boolean>(false);
  const [enableImageInsert, setEnableImageInsert] = useState<boolean>(false);
  const [imageOptions, setImageOptions] = useState<IDropdownOption[]>([]);
  //const [imageToInsert, setImageToInsert] = useState<IDropdownOption>();

  const loadContent = useCallback(() => {
    const handleHtmlApiCall = async () => {
      try {
        const content = await get_content_file(environment, {
          OEM,
          country,
          lang: locale,
          type: EApiResponseType.html,
          page_name,
        });
        setHtmlContent(content);
        editor_html_ref.current = content;
        //console.log(editor_html_ref.current);
      } catch (ex) {
        console.error(ex);
      }
    };
    const handleLoadImageList = async () => {
      // fetch list of images
      const images = await get_image_list(OEM, country);
      setImageOptions(
        images.map(({ id, key, cloudfront_link }: ImageRow) => ({
          id,
          key,
          url: cloudfront_link,
          label: key?.split("/").pop() || "",
        }))
      );
    };
    handleHtmlApiCall();
    handleLoadImageList();
  }, [OEM, country, page_name, locale]);
  // useEffect(() => {
  //   loadContent();
  // }, [loadContent]);

  useEffect(() => {
    loadContent();
    setShowEditorCode(false);
  }, [OEM, country, page_name, locale]);

  const handleShowCodeClick = () => {
    setShowEditorCode(!showEditorCode);
    if (showEditorCode) setEditorHeight("40em");
    if (!showEditorCode) setEditorHeight("20em");
  };
  const handleHtmlEditorChange = (editor_html: string) => {
    setSaveEnabledState(editor_html !== editor_html_ref.current);
    setHtmlContent(editor_html);
    editor_html_ref.current = editor_html;
    //handleSave(editorState.getCurrentContent().getPlainText());
  };
  const handleBlur = (event: FocusEvent<HTMLDivElement>) => {
    setEnableImageInsert(false);
    const editor_html = event.target.innerHTML;
    const sanitized_html = `<link href="${css_link}" rel="stylesheet" /><div class="info-content">${sanitizeHtml(
      editor_html,
      sanitize_conf
    )}</div>`;
    setHtmlContent(sanitized_html);
    editor_html_ref.current = sanitized_html;
  };
  const handleChange = (event: ContentEditableEvent) => {
    const editor_html = event.target.value;
    setSaveEnabledState(editor_html !== editor_html_ref.current);
    setHtmlContent(editor_html);
    editor_html_ref.current = editor_html;
  };
  const handleSaveClick = async () => {
    await edit_html_file(editor_html_ref.current, {
      OEM,
      country,
      lang: locale,
      page_name,
      type: EApiResponseType.html,
    });
    setSaveEnabledState(false);
  };
  const handleInsertPicture = async () => {
    // show picture insert popup
    setShowImageChoice(!showImageChoice);
  };
  const handleChangeImageToInsert = (selected: IDropdownOption | undefined) => {
    //setImageToInsert(selected);
    if (selected && selectedText) {
      const { url } = selected;

      const with_image = htmlContent.replace(selectedText, `${selectedText}<p><img src="${url}" /></p>`);
      setHtmlContent(with_image);
      editor_html_ref.current = with_image;
    }
    setShowImageChoice(false);
  };
  //event: MouseEvent<HTMLDivElement>
  const handleSelection = () => {
    const selection = window.getSelection();
    // if(selection) {
    //   setSelectedText(selection.toString());
    // }
    if (
      selection &&
      selection.focusNode &&
      selection.focusNode.parentNode &&
      selection.focusNode.parentNode.textContent
    ) {
      const { textContent, nodeName } = selection.focusNode.parentNode;
      setSelectedText(`<${nodeName.toLowerCase()}>${textContent}</${nodeName.toLowerCase()}>`);
      setEnableImageInsert(true);
    }
    // setEditorTarget(event.target.);
    // setEnableImageInsert(true);
  };
  return (
    <Box sx={{ minHeight: "6em", paddingBottom: "30px" }}>
      <Typography variant="h5">{locale}</Typography>
      <Box sx={{ border: "1px solid gray" }}>
        <Box sx={{}}>
          <SaveContent enabled={saveEnabledState} handleSave={handleSaveClick} />
          <InsertPicture enabled={enableImageInsert} handleClick={handleInsertPicture} />
          <ShowHtmlCode handleClick={handleShowCodeClick} />
        </Box>
        <Box sx={{ display: "flex", marginLeft: "50px" }}>
          <Fade in={showImageChoice}>
            <Box sx={{ position: "absolute" }}>
              <Dropdown
                id="select-insert-image"
                options={imageOptions}
                selectedValue={undefined}
                handleChange={handleChangeImageToInsert}
              />
            </Box>
          </Fade>
        </Box>
        <Box>
          <ContentEditable
            style={{ height: editorHeight, overflowY: "scroll" }}
            html={editor_html_ref.current} // innerHTML of the editable div
            disabled={false} // use true to disable editing
            onChange={handleChange} // handle innerHTML change
            onBlur={handleBlur}
            //onFocus={handleFocus}
            onMouseUp={handleSelection}
            //onSelect={handleSelection}
          />
          <HtmlCodeEditor
            htmlContent={editor_html_ref.current}
            visible={showEditorCode}
            handleAfterChange={handleHtmlEditorChange}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default HtmlEditor;
