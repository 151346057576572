import { FormFieldInput } from "components/atoms/form-field-input";
import { FormFieldSelect } from "components/atoms/form-field-select";
import { EFormFieldTypes, IDropdownOption, IValidationRule } from "components/shared";
import React from "react";

export interface IFormField {
  id: string;
  type: EFormFieldTypes;
  label: string;
  validation: IValidationRule[];
  handleChange: (id: string, value: string) => void;
  handleValidate: (id: string) => void;
  value?: string;
  options?: IDropdownOption[];
  placeHolder?: string;
  required?: boolean;
}

export const FormField: React.FC<IFormField> = ({
  id,
  value,
  label,
  required,
  handleChange,
  handleValidate,
  options,
  validation,
}) => {
  const texts: string[] = [];
  validation.forEach((rule) => {
    if (rule.result === false) texts.push(rule.errorText);
  });
  const error = texts.length > 0;
  if (options) {
    return (
      <FormFieldSelect
        id={id}
        label={label}
        value={value}
        options={options}
        handleChange={handleChange}
        handleValidate={handleValidate}
        required={required}
        helperText={texts}
        error={error}
      />
    );
  }

  return (
    <FormFieldInput
      id={id}
      label={label}
      value={value}
      handleChange={handleChange}
      handleValidate={handleValidate}
      required={required}
      helperText={texts}
      error={error}
    />
  );
};
