/* eslint-disable @typescript-eslint/no-empty-function */
import { environmentTypes } from "components/shared";
import { FC, ReactNode, createContext, useEffect, useState } from "react";

import Cookies from "universal-cookie";

const cookies = new Cookies();

export interface IContext {
  environment: environmentTypes;
  setEnvironment: (value: environmentTypes) => void;
  setCookies: (key: string, value: string) => void;
  getCookies: (key: string) => string;
  clearCookies: (key: string) => void;
  userEmail: string;
  setUserEmail: (value: string) => void;
  userToken: string;
  setUserToken: (value: string) => void;
  logout: () => void;
  dialogOpen?: string;
  setDialogOpen?: (value: string) => void;
}

interface IGlobalContextProviderProps {
  children: ReactNode;
}

const GlobalContext = createContext<IContext>({
  environment: "review",
  userEmail: "",
  userToken: "",
  setEnvironment: () => {},
  setCookies: () => {},
  getCookies: () => "",
  clearCookies: () => {},
  setUserEmail: () => {},
  setUserToken: () => {},
  logout: () => {},
});

export const GlobalContextProvider: FC<IGlobalContextProviderProps> = ({ children }) => {
  const [environment, setEnvironment] = useState<environmentTypes>("review");

  const userEmail = sessionStorage.getItem("userEmail") || "";
  const userToken = sessionStorage.getItem("userToken") || "";

  const handleSetEnvironment = (env: environmentTypes) => {
    sessionStorage.setItem("environment", env);
    setEnvironment(env);
  };

  const handleSetCookies = (key: string, value: string | undefined) => cookies.set(key, value);

  const handleGetCookies = (key: string) => cookies.get(key);

  const handleClearCookies = (key: string) => cookies.remove(key);

  const handleSetUserEmail = (value: string) => sessionStorage.setItem("userEmail", value);

  const handleSetUserToken = (value: string) => sessionStorage.setItem("userToken", value);

  const handleLogout = () => {
    console.log("Logout");
    sessionStorage.removeItem("userEmail");
    sessionStorage.removeItem("userToken");
  };

  useEffect(() => {
    const env = sessionStorage.getItem("environment") || process.env?.REACT_APP_ENV_STAGE;
    setEnvironment(env as environmentTypes);
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        environment,
        userEmail,
        userToken,
        setEnvironment: handleSetEnvironment,
        setCookies: handleSetCookies,
        getCookies: handleGetCookies,
        clearCookies: handleClearCookies,
        setUserEmail: handleSetUserEmail,
        setUserToken: handleSetUserToken,
        logout: handleLogout,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
