import React from "react";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

export const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => {
    const query: Record<string, string> = {};
    const search_params = new URLSearchParams(search);
    search_params.forEach((value, key) => (query[key] = value));
    return query;
  }, [search]);
};

export const useDebounce = <T,>(value: T, delay = 1000) => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
};

export default useDebounce;
