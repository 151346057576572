import * as React from "react";
import { SxProps } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import { SimpleCheckbox } from "components/atoms/simple-checkbox";

export interface ICheckboxListOption {
  label: string;
  id: string;
  selected: boolean;
}
export interface CheckboxListProps {
  id?: string;
  options: ICheckboxListOption[];
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, option: ICheckboxListOption) => void;
  add_all_option?: boolean;
  wrapperCss?: SxProps;
}

export const CheckboxList: React.FC<CheckboxListProps> = ({
  options,
  handleChange,
  add_all_option,
  wrapperCss,
  id = "checkbox-list",
}) => {
  const [isAllChecked, setIsAllChecked] = React.useState<boolean>(
    options.filter(({ selected }) => selected).length === options.length
  );
  const [checkboxList, setCheckboxList] = React.useState<ICheckboxListOption[]>([]);
  const handleAllSelectChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setIsAllChecked(checked);
    const new_list = checkboxList.map((option) => ({
      ...option,
      selected: checked,
    }));
    setCheckboxList(new_list);
    new_list.forEach((option) => handleChange(event, checked, option));
  };
  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, option_id: string) => {
    const new_list = checkboxList.map(({ id, selected, label }) => {
      if (id === option_id) return { id, selected: checked, label };
      return { id, selected, label };
    });
    setCheckboxList(new_list);
    const option = new_list.find(({ id }) => id === option_id);
    if (option) {
      handleChange(event, checked, option);
    }
  };
  React.useEffect(() => {
    setCheckboxList(options);
  }, [options, setCheckboxList]);
  return (
    <FormGroup id={id} sx={wrapperCss}>
      {add_all_option ? (
        <SimpleCheckbox
          selected={isAllChecked}
          id="select-all"
          handleChange={(event, checked) => handleAllSelectChange(event, checked)}
          label="Select/deselect all"
        />
      ) : (
        ""
      )}
      {checkboxList.map(({ label, id, selected }) => (
        <SimpleCheckbox
          key={id}
          label={label}
          id={id}
          selected={selected}
          handleChange={(event, checked) => {
            handleSelectChange(event, checked, id);
          }}
        />
      ))}
    </FormGroup>
  );
};
