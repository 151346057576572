import { GridColDef } from "@mui/x-data-grid";

const columns: GridColDef[] = [
  {
    field: "country",
    headerName: "Country",
    width: 90,
    editable: true,
  },
  {
    field: "oem",
    headerName: "OEM",
    width: 200,
    editable: true,
  },
  {
    field: "ip",
    headerName: "IP(s)",
    width: 180,
    editable: true,
  },
];

export interface ICidrEntry {
  environment: string;
  country: string;
  oem: string;
  ip: string;
  id: string;
}

export interface ICidrRow {
  id: string;
  tic_bmw: string;
  tic_volvo: string;
  tua: string;
  bmw_tua: string;
  volvo_tua: string;
  prod: string;
  bmw_prod: string;
  volvo_prod: string;
  poc: string;
}

export const row_maker = (
  id: string,
  country: string,
  tic_bmw: string,
  tic_volvo: string,
  tua: string,
  bmw_tua: string,
  volvo_tua: string,
  prod: string,
  bmw_prod: string,
  volvo_prod: string,
  poc: string
) => {
  return {
    id,
    country,
    tic_bmw,
    tic_volvo,
    tua,
    bmw_tua,
    volvo_tua,
    prod,
    bmw_prod,
    volvo_prod,
    poc,
  };
};
export const generate_empty_row = (old: ICidrRow[]) => {
  const new_row = row_maker(`country_${old.length}`, "A", "B", "C", "D", "E", "F", "G", "H", "J", "K");
  return new_row;
};

export default columns;
