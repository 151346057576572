import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ArticleIcon from "@mui/icons-material/Article";
import HtmlIcon from "@mui/icons-material/Html";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { SxProps } from "@mui/material";
import { parse_content_key } from "api/content";
import {
  IContentRowActions,
  IContentInfo,
  getContentUrl,
  editable_pages,
  EUploadDocumentTypes,
} from "components/shared";
import { ChangeEvent, FC, MouseEvent, useState } from "react";
// import FormData from "form-data"

const Input = styled("input")({
  display: "none",
});

interface ContentRowActionsProps {
  id?: string;
  actions: IContentRowActions;
  handleUpload: (file: Blob, key: string, fileType: keyof typeof EUploadDocumentTypes) => Promise<void>;
  handleEditClick: (req: IContentInfo) => void;
  content_base_url: string;
  //filter?: ContentRow;
  wrapperCss?: SxProps;
}

const ContentRowActions: FC<ContentRowActionsProps> = ({
  actions,
  handleUpload,
  handleEditClick,
  content_base_url,
  wrapperCss,
  id = "content-row-actions",
}) => {
  const [uploadInProgress, setUploadInProgress] = useState("none");
  //const [contentRow, setContentRow] = useState(content);
  const { key } = actions;
  const page_name = key?.split("/")?.at(-1)?.split(".")[0] || "";

  let allowedFileType = EUploadDocumentTypes.docx;

  if (editable_pages.includes(page_name)) allowedFileType = EUploadDocumentTypes.html;
  if (actions.oem === "hotspotdrive") allowedFileType = EUploadDocumentTypes.pdf;

  const url = getContentUrl(content_base_url, key);

  const handleUploadCall = async (event: ChangeEvent<HTMLInputElement>) => {
    //callback?: (err: Error, data: ManagedUpload.SendData) => void) => {mt,
    try {
      setUploadInProgress("flex");
      const {
        target: { files, value },
      } = event;

      // tests weather the file has the right extension
      const pattern = new RegExp(`(${allowedFileType})$`, "i");

      if (pattern.test(value) && files && files.length) {
        const fileExtension = value
          .replace(/^.*[\\/]/, "")
          .split(".")
          .pop();

        await handleUpload(files[0], key, `${fileExtension}` as keyof typeof EUploadDocumentTypes);
        event.target.value = "";
        setUploadInProgress("none");
      } else {
        throw `Invalid file type, use file with extension ${allowedFileType}`;
      }
    } catch (ex) {
      event.target.value = "";
      setUploadInProgress("none");
      throw ex;
    }
  };

  const handleDownload = (event: MouseEvent, type: string) => {
    event.stopPropagation();
    const el = document.createElement("a");
    const fileName = url.split("/").pop();
    el.setAttribute("href", url.replaceAll("html", type));
    el.setAttribute("target", "_blank");
    el.setAttribute("download", fileName ? fileName : "");
    document.body.appendChild(el);
    el.click();
    el.remove();
  };

  const handleHtmlEditClick = () => {
    handleEditClick(parse_content_key(key));
  };

  return (
    <Box id={id} sx={{ ...wrapperCss, display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Box sx={{ display: !editable_pages.includes(page_name) ? "inline-block" : "none" }}>
        <IconButton
          aria-label="download pdf file"
          component="span"
          onClick={(event: MouseEvent) => handleDownload(event, "pdf")}
        >
          <PictureAsPdfIcon />
        </IconButton>
      </Box>
      {actions.oem !== "hotspotdrive" && (
        <>
          <Box sx={{ display: !editable_pages.includes(page_name) ? "inline-block" : "none" }}>
            <IconButton
              aria-label="download docx file"
              component="span"
              onClick={(event: MouseEvent) => handleDownload(event, "docx")}
            >
              <ArticleIcon />
            </IconButton>
          </Box>
          <Box sx={{ display: "inline-block" }}>
            <IconButton
              aria-label="download html file"
              component="span"
              onClick={(event: MouseEvent) => handleDownload(event, "html")}
            >
              <HtmlIcon />
            </IconButton>
          </Box>
        </>
      )}
      <label htmlFor={`icon-button-file-${id}`}>
        <Input
          accept={allowedFileType}
          id={`icon-button-file-${id}`}
          type="file"
          onChange={(event) => handleUploadCall(event)}
        />
        <IconButton aria-label={`upload ".${allowedFileType}" content`} component="span">
          <FileUploadOutlinedIcon />
        </IconButton>
      </label>
      <Box sx={{ display: editable_pages.includes(page_name) ? "inline-block" : "none" }}>
        <IconButton aria-label="edit html content" component="span" onClick={handleHtmlEditClick}>
          <EditIcon />
        </IconButton>
      </Box>
      <Box sx={{ display: uploadInProgress }}>
        <CircularProgress size={24} />
      </Box>
    </Box>
  );
};

export default ContentRowActions;
