import React from "react";
import CodeIcon from "@mui/icons-material/Code";
import CodeOffIcon from "@mui/icons-material/CodeOff";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";

export interface ShowHtmlCodeProps {
  id?: string;
  handleClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const ShowHtmlCode: React.FC<ShowHtmlCodeProps> = ({ id = "show-html-code", handleClick }) => {
  const [codeVisible, setCodeVisible] = React.useState<boolean>(false);
  const handleCodeShow = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setCodeVisible(!codeVisible);
    handleClick(event);
  };
  return (
    <Box id={id} sx={{ display: "inline-block" }}>
      <Box onClick={handleCodeShow} sx={{ display: codeVisible ? "none" : "inline" }}>
        <IconButton>
          <CodeIcon style={{ color: "black" }} />
        </IconButton>
      </Box>
      <Box onClick={handleCodeShow} sx={{ display: codeVisible ? "inline" : "none" }}>
        <IconButton>
          <CodeOffIcon style={{ color: "black" }} />
        </IconButton>
      </Box>
    </Box>
  );
};
