import {
  IDropdownOption,
  INamespaceOption,
  country_mapping,
  getDropdownOption,
  namespaces_jumpboard,
} from "components/shared";
import { getParamsStringTypes } from "./utils-types";

export const setUrlParamsStr = (paramsObj: getParamsStringTypes) => {
  let paramsStr = "";
  let trigger = true;

  for (const [key, value] of Object.entries(paramsObj)) {
    if (trigger && value && value != null && paramsStr === "") {
      paramsStr += `?${key}=${value}`;
      trigger = false;
    } else if (value && value != null && paramsStr !== "") {
      paramsStr += `&${key}=${value}`;
    }
  }

  return paramsStr;
};

export const getOemAvailableCountries = (oem?: IDropdownOption): INamespaceOption[] => {
  if (!oem) return namespaces_jumpboard;

  if (oem.id === "hotspotdrive") return namespaces_jumpboard;

  return country_mapping
    .filter(({ oem_list }) => oem_list.includes(oem.id))
    .map(({ id, label }) => ({ id, label, key: id, country: id, page: "common" }));
};

export const getOemAvailableLocales = (namespace: string) => {
  const availableLocales = country_mapping.find(({ id }) => id === namespace)?.locales || [];

  return availableLocales.map((locale) => getDropdownOption(locale));
};

export const generateRandomID = () => {
  const datetime_now = new Date();
  const encoded_timestamp = datetime_now.getTime().toString(36);
  const random_10_characters = Math.random().toString(36).substring(2, 12);
  return `${random_10_characters}-${encoded_timestamp}`;
};
