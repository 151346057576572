import { FC, useState, useEffect } from "react";
import { country_options, getDropdownOption, namespaces_jumpboard, namespaces_oem } from "components/shared/constants";
import { Dropdown } from "components/atoms/dropdown";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ContentRowActions from "components/molecules/content-row-actions";
import { SxProps } from "@mui/material";
import {
  ContentRow,
  IDropdownOption,
  IContentInfo,
  EOEMValues,
  INamespaceOption,
  EUploadDocumentTypes,
} from "components/shared";
import { getOemAvailableCountries, getOemAvailableLocales } from "utils";
import { DataTable } from "components/atoms/data-table";
//import { GlobalContext } from 'root';

interface ContentViewerProps {
  //rows: ContentRow[];
  //filter?: ContentRow;
  content_rows: Array<ContentRow>;
  content: string;
  OEMs: Array<string>;
  handleUpload: (file: Blob, key: string, fileType: keyof typeof EUploadDocumentTypes) => Promise<void>;
  handleChangeOEM: (
    signal?: AbortSignal,
    oem?: IDropdownOption | undefined,
    content?: string,
    namespace?: string,
    language?: string
  ) => void;
  handleEditClick: (req: IContentInfo) => void;
  content_base_url: string;
  wrapperCss?: SxProps;
  isLoading?: boolean;
}

const ContentViewer: FC<ContentViewerProps> = ({
  content_rows,
  OEMs,
  content,
  handleUpload,
  handleChangeOEM,
  handleEditClick,
  isLoading = false,
  content_base_url,
  wrapperCss,
}) => {
  //const globalState = useContext(GlobalContext);
  const [rows, setRows] = useState<Array<ContentRow>>([]);
  const [oem, setOem] = useState<IDropdownOption>();
  const [langOptions, setLangOptions] = useState<Array<IDropdownOption>>([]);
  const [lang, setLang] = useState<IDropdownOption>();
  const [oemOptions, setOemOptions] = useState<string[]>([]);
  const [namespaces, setNamespaces] = useState<INamespaceOption[]>([]);
  const [namespace, setNamespace] = useState<INamespaceOption>();

  const changeOem = (oem?: IDropdownOption) => {
    setOem(oem);
    setLang(undefined);
    setNamespace(undefined);
    const availableNamespaces = getOemAvailableCountries(oem);
    setNamespaces(availableNamespaces);
  };

  const changeNamespace = (namespace_id?: string, oem_new?: string) => {
    const use_namespaces = oem_new === EOEMValues.hotspotdrive ? namespaces_jumpboard : namespaces_oem;
    const namespace_new = namespace_id ? use_namespaces.find(({ id }) => id === namespace_id) : undefined;
    setNamespace(namespace_new);
    setLang(undefined);

    const available_locales = getOemAvailableLocales(namespace_id || "");
    if (available_locales.length === 1) setLang(available_locales[0]);
    setLangOptions(available_locales);
  };

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    (async () => {
      handleChangeOEM(signal, oem, content, namespace?.id, lang?.id);
    })();

    return () => {
      abortController.abort();
    };
  }, [OEMs, oem, content, namespace, lang]);
  useEffect(() => {
    setRows(content_rows);
    setOemOptions(OEMs);
  }, [setRows, setOemOptions, content_rows, OEMs]);
  return (
    <Box sx={wrapperCss}>
      <Grid container>
        <Grid item xs={12} sx={{ mb: 3 }}>
          <Box>
            <Dropdown
              id="select-oem"
              placeholder="OEM"
              options={oemOptions.map((oem) => getDropdownOption(oem))}
              selectedValue={oem}
              handleChange={(oem) => changeOem(oem)}
              sx={{ minWidth: "180px", mr: 1 }}
            />
            <Dropdown
              id="select-namespace"
              placeholder={oem?.id === EOEMValues.hotspotdrive ? "Namespace" : "Country"}
              options={namespaces}
              selectedValue={namespace}
              handleChange={(ns) => changeNamespace(ns?.id, oem?.id)}
              sx={{ minWidth: "180px", mr: 1 }}
            />
            <Dropdown
              id="select-language"
              placeholder="Language"
              options={langOptions}
              selectedValue={lang}
              handleChange={(lang?: IDropdownOption) => {
                setLang(lang);
              }}
              sx={{ minWidth: "180px" }}
            />
          </Box>
        </Grid>
      </Grid>
      <DataTable
        rows={rows.map(({ type, lang, country, key }) => ({
          type,
          lang,
          oem,
          key,
          country,
          id: key.toString().replace("/", "_"),
        }))}
        columns={[
          { headerName: "Country", field: "country", width: 250 },
          {
            headerName: "Language",
            field: "lang",
            width: 250,
            valueOptions: country_options.map((country) => ({
              value: country,
              label: country.label,
            })),
            valueGetter: (_, { key }) => key.split("/").at(-2) || "",
          },
          {
            headerName: "Document",
            field: "document",
            width: 250,
            valueGetter: (_, { key }) => key.split("/")?.at(-1)?.split(".")[0] || "",
          },
          {
            headerName: "Actions",
            field: "actions",
            width: 250,
            sortable: false,
            renderCell: ({ row: { type, key, oem, id } }) =>
              oem ? (
                <ContentRowActions
                  id={`content-row-actions-${id}`}
                  content_base_url={content_base_url}
                  handleUpload={handleUpload}
                  handleEditClick={handleEditClick}
                  actions={{ type, key, oem: oem.id }}
                />
              ) : (
                ""
              ),
          },
        ]}
        loading={isLoading}
      />
    </Box>
  );
};

export default ContentViewer;
