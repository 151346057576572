import CssBaseline from "@mui/material/CssBaseline";
import { EProductType } from "api/products";
import CheckAuth from "components/templates/check_auth";
import Main from "components/templates/main";
import defaultTheme from "components/theme";
import { ThemeProvider } from "components/theme/theme-provider";
import CidrPage from "pages/cidr";
import CidrForm from "pages/cidr_form";
import ContentPage from "pages/content";
import HtmlEditorPage from "pages/content/html_editor";
import FaqPage from "pages/faq";
import HomePage from "pages/home";
import LoginPage from "pages/login";
import LoginConfirmPage from "pages/login/confirm";
import OEMProductsPage from "pages/oem_products";
import PassesPage from "pages/passes";
import ProductCardPage from "pages/product_card";
import SubscriptionsPage from "pages/subscriptions";
import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RecoilRoot } from "recoil";
import { GlobalContextProvider } from "root/context";

const App = () => (
  <RecoilRoot>
    <GlobalContextProvider>
      <BrowserRouter>
        <ThemeProvider theme={defaultTheme}>
          <ToastContainer />
          <CssBaseline />
          <Suspense fallback={<div>Loading...</div>}>
            <Main>
              <Routes>
                <Route path="" element={<CheckAuth />}>
                  <Route path="login">
                    <Route index element={<LoginPage />} />
                    <Route path="confirm" element={<LoginConfirmPage />} />
                  </Route>
                  <Route index element={<HomePage />} />
                  <Route path="cidr">
                    <Route index element={<CidrForm />} />
                    <Route path=":env" element={<CidrPage />} />
                  </Route>
                  <Route path="html-editor/:OEM/:country/:page_name" element={<HtmlEditorPage />} />
                  <Route path=":content" element={<ContentPage />} />
                  <Route path="faq" element={<FaqPage />} />
                  <Route path="subscriptions" element={<OEMProductsPage type={EProductType.subscription} />} />
                  <Route path="subscriptions/:OEM" element={<SubscriptionsPage />} />
                  <Route
                    path="subscriptions/:OEM/:productSK"
                    element={<ProductCardPage type={EProductType.subscription} />}
                  />
                  <Route path="passes" element={<OEMProductsPage type={EProductType.pass} />} />
                  <Route path="passes/:OEM" element={<PassesPage />} />
                  <Route path="passes/:OEM/:productSK" element={<ProductCardPage type={EProductType.pass} />} />
                </Route>
              </Routes>
            </Main>
          </Suspense>
        </ThemeProvider>
      </BrowserRouter>
    </GlobalContextProvider>
  </RecoilRoot>
);

export default App;
