import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { EProductType } from "api/products";
import { oem_options } from "components/shared/constants";
import { useNavigate } from "react-router-dom";

const OEMProductsPage = ({ type }: { type: EProductType }) => {
  const navigate = useNavigate();
  const handleOEMClick = (OEM: string) => {
    if (type === EProductType.pass) navigate(`/passes/${OEM}`);
    navigate(`/subscriptions/${OEM}`);
  };

  return (
    <Grid container spacing={4}>
      {oem_options.map(({ id, label }) => (
        <Grid item xs={12} md={6} lg={4} key={`${id}-${label}`}>
          <Card>
            <CardActionArea onClick={() => handleOEMClick(id)} sx={{ minHeight: 150 }}>
              <CardContent>
                <DirectionsCarIcon />
                <Typography variant="h5" component="div">
                  {label}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {`Edit product catalog for ${label}`}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default OEMProductsPage;
