import type { EProductType } from "api/products";
import ProductCardEditor from "components/organisms/product-card-editor";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { GlobalContext } from "root";

const ProductCardPage = ({ type }: { type: EProductType }) => {
  const { environment } = useContext(GlobalContext);
  const { productSK, OEM } = useParams();

  return <ProductCardEditor productSK={productSK ?? ""} environment={environment} OEM={OEM ?? ""} type={type} />;
};

export default ProductCardPage;
